const environment = {
  baseUrl: "http://obektiva-api.test/api",
  // baseUrl: "https://staging.obektiva.com/api",
};

if (process.env.REACT_APP_ENV === "staging") {
  environment.baseUrl = "https://staging.obektiva.com/api";
}
if (process.env.REACT_APP_ENV === "production") {
  environment.baseUrl = "https://obektiva.com/api";
}

export default environment;
