import { Box, Typography } from '@mui/material';
import Header from '../../components/header.components';
import MobileSettingsForm from '../../components/auth/user/mobile-settings-form.component';
import BackButton from '../../components/ui/back-button.component';

export const MobileSettings = () => {
    return (
        <Header>
            <Box sx={{ position: 'relative', height: '100vh' }}>
                <BackButton
                    sx={{
                        backgroundColor: '#F1F1F1',
                        position: 'absolute',
                        top: 0,
                        left: 10,
                        zIndex: 1000,
                    }} />
                <Typography sx={{ color: 'black', textAlign: 'center', marginTop: 2 }} variant='h5'>Profile</Typography>

                <Box mt={10}>
                    <MobileSettingsForm />
                </Box>
            </Box>
        </Header>
    )
}
