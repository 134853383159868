import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    textTransform: "none",
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
        },
      },
    },
  },
});

export default theme;
