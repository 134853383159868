import React, { useContext, useEffect, useState } from "react";
import { getUserData, userLogout } from "../services/auth.services";
import { useQuery } from "@tanstack/react-query";
import { favoritePhoto } from "../services/user.services";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isLoaded, setIsLoaded] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [currentUser, setCurrentUser] = useState();
  const [currentToken, setCurrentToken] = useState(null);

  const {
    data: token,
    isLoading,
    isFetched: tokenIsFetched,
  } = useQuery({
    queryKey: ["user-me", "token"],
    queryFn: async () => {
      const storedToken = localStorage.getItem("token");
      return storedToken;
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(true);
      try {
        const result = await getUserData(token);

        if (result?.message === "Unauthenticated.") {
          throw new Error("Unauthorized");
        }

        if (result?.user && tokenIsFetched) {
          setCurrentUser(result?.user);
          setCurrentToken(token);
          setIsLoaded(false);
        }
        if (result?.user?.message) {
          setCurrentUser(undefined);
          localStorage.removeItem("token");
          setIsLoaded(false);
        }
      } catch (error) {
        localStorage.removeItem("token");
        setCurrentUser(undefined);
        setIsLoaded(false);
      } finally {
        setIsLoaded(false);
      }
    };

    setIsLoaded(true);
    if (token && tokenIsFetched) {
      fetchData();
    }
    setIsLoaded(false);
  }, [tokenIsFetched]);

  async function changeCurrentUser(token) {
    setIsLoaded(true);
    try {
      const result = await getUserData(token);

      setCurrentUser(result?.user);
      setCurrentToken(token);
      setIsLoaded(false);
    } catch (error) {
      setIsLoaded(false);
    }
  }

  async function userLogoutHandler() {
    setIsLoaded(true);
    try {
      const result = await userLogout(currentToken);
      if (result?.status === 200) {
        localStorage.removeItem("token");
        setCurrentUser(undefined);
        setCurrentToken(undefined);
      }
    } catch (error) {
    } finally {
      setIsLoaded(false);
    }
  }

  const favoriteHandler = async (photoId, refetch, navigate) => {
    if (!currentToken) {
      setErrorMessage("You need to log in into your account.");
      return navigate("/login");
    }
    const result = await favoritePhoto(photoId, currentToken);

    if (result?.status === 200) {
      await refetch();
      setSuccessMessage(result?.data?.message);
    }
  };

  const value = {
    isLoaded,
    setIsLoaded,
    currentUser,
    setCurrentUser,
    currentToken,
    setCurrentToken,
    errorMessage,
    setErrorMessage,
    successMessage,
    setSuccessMessage,
    changeCurrentUser,
    userLogoutHandler,
    tokenIsFetched,
    favoriteHandler,
  };

  return (
    <AuthContext.Provider value={value}>
      {!isLoading && tokenIsFetched && children}
    </AuthContext.Provider>
  );
}
