import * as yup from 'yup';
import { TextField, Button, Grid } from '@mui/material';
import { useAuth } from '../../../contexts/auth-context';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { updateUser } from '../../../services/user.services';

const validationSchema = yup.object({
    old_password: yup
        .string()
        .required('Old Password is required'),
    password: yup
        .string()
        .min(6, 'Password should be at least 6 characters long!')
        .required('Password is required'),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match!')
        .required('Confirm Password is required'),
});

const MobileChangePasswordForm = () => {
    const { currentToken, setIsLoaded, setErrorMessage, changeCurrentUser } = useAuth();

    async function onSubmitHandler(values) {
        setIsLoaded(true);
        setErrorMessage('');
        const result = await updateUser(values, currentToken);

        if (result?.status === 200) {
            await changeCurrentUser(currentToken);
        } else {
            setErrorMessage(result);
        }
        setIsLoaded(false);
    }

    return (
        <Grid container justifyContent="center">
            <Grid item xs={10}>
                <Formik
                    enableReinitialize
                    onSubmit={onSubmitHandler}
                    validationSchema={validationSchema}
                    initialValues={{ old_password: '', password: '', password_confirmation: '' }}
                >
                    <Form>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                fullWidth
                                as={TextField}
                                type="password"
                                label="Old Password"
                                margin="normal"
                                id="old_password"
                                variant="outlined"
                                name="old_password"
                            />
                            <ErrorMessage name="old_password" component="div" style={{ color: 'red', mb: 5 }} />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                as={TextField}
                                type="password"
                                label="New Password"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                id="password"
                                name="password"
                            />
                            <ErrorMessage name="password" component="div" style={{ color: 'red', mb: 5 }} />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                as={TextField}
                                type="password"
                                label="Confirm Password"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="password_confirmation"
                                name="password_confirmation"
                            />
                            <ErrorMessage name="password_confirmation" component="div" style={{ color: 'red', mb: 5 }} />
                        </Grid>

                        <Button type="submit" variant="contained" color="success" fullWidth sx={{ borderRadius: 15, mt: 3, height: 50 }}>
                            Save changes
                        </Button>
                    </Form>
                </Formik>
            </Grid>
        </Grid>
    );
};

export default MobileChangePasswordForm;
