import { request } from "../helpers/request";

export async function updateUser(values, token) {
  let formData = new FormData();

  if (values?.name) {
    formData.append("name", values.name);
  }

  if (values?.avatarImage) {
    formData.append("avatar", values.avatarImage);
  }

  if (
    values?.old_password &&
    values?.password &&
    values?.password_confirmation
  ) {
    formData.append("old_password", values.old_password);
    formData.append("password", values.password);
    formData.append("password_confirmation", values.password_confirmation);
  }

  try {
    const result = await request.post(`/user/update`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result;
  } catch (error) {
    return "Something went wrong!";
  }
}

export async function favoritePhoto(photoId, token) {
  try {
    const response = await request.post(
      `/photographies/${photoId}/favour`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return { error: "Something went wrong!" };
    }
  }
}

export async function getFavoritesPhotos(currentToken) {
  try {
    const response = await request.get("/user/favorites", {
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Something went wrong");
  }
}
