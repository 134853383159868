import { useQuery } from "@tanstack/react-query";
import { getSiteInfo, getIpLocation } from "../services/settings.services";
import React, { useContext, useState, useEffect } from "react";

const SettingsContext = React.createContext();
export function useSettings() {
  return useContext(SettingsContext);
}

export function SettingsProvider({ children }) {
  const [isMobile, setIsMobile] = useState(false);
  const [userLocation, setUserLocation] = useState({
    latitude: sessionStorage.getItem('currentLat') ?? '',
    longitude: sessionStorage.getItem('currentLong') ?? ''
  });

  const { data, isLoading, isFetched } = useQuery({
    queryKey: ["/settings"],
    queryFn: () => getSiteInfo(),
  });

  const { isSuccess } = useQuery({
    queryKey: ["ip-location"],
    queryFn: () => getIpLocationHandler(),
  });

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      );
    };
    checkIsMobile();

    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  async function getIpLocationHandler() {
    if (
      !sessionStorage.getItem("currentLat") &&
      !sessionStorage.getItem("currentLong") &&
      !userLocation.latitude && !userLocation.longitude
    ) {
      let ipCoordinates = await getIpLocation();
      setUserLocation(ipCoordinates?.message ? { latitude: 42.7662665, longitude: 25.2385780 } : ipCoordinates);
      return ipCoordinates;
    } else {
      return true;
    }
  }

  const value = {
    siteInfo: data,
    mapTags: data?.map_tags,
    loading: isLoading,
    isMobile,
    userLocation,
    setUserLocation,
    getIpLocationHandler,
  };

  return (
    <SettingsContext.Provider value={value}>
      {!isLoading && userLocation?.latitude && children}
    </SettingsContext.Provider>
  );
}
