import axios from "axios";
import { request } from "../helpers/request";

//logo, name, desc, meta-tags, privacy-and-policy and support
export function getSiteInfo() {
  let result = request
    .get(`/settings/site-info`, {
      headers: {
        "api-lang": localStorage.getItem("lang"),
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
  return result;
}

export function getRandomImage() {
  let result = request.get(`/random-image`, {}).then((res) => res.data);
  return result;
}

export function getDailyPhoto() {
  let result = request.get(`/daily-photography`, {}).then((res) => res.data);
  return result;
}

export async function getIpLocation() {
  try {
    const res = await axios.get(`https://obektiva.com/api/get-ip-location`);
    return res.data;
  } catch (err) {
    return err.response.data;
  }
}
