import { useEffect, useRef } from 'react';
import NoResultPage from './NoResultPage.jsx';
import { usePhotographs } from '../hooks/usePhotos.js';
import Header from '../components/header.components.jsx';
import { useSettings } from '../contexts/settings-context.js';
import { Grid, Box, Typography, CircularProgress, Skeleton, ButtonGroup, Button, Divider } from '@mui/material';
import { usePhotographsContext } from '../contexts/photographs-context.js';
import MobileFilter from '../components/home/mobile-filter.component.jsx';
import PhotographyCard from '../components/home/photography-card.component.jsx';
import PhotographsFilter from '../components/home/photographs-filter.component.jsx';
import FooterComponent from '../components/footer.component.jsx';

const Home = () => {
    const ref = useRef()
    const { isMobile, userLocation, mapTags } = useSettings();
    const { clickedTags, showMobileFilter, filter, setFilter } = usePhotographsContext();
    const { data, photoData, isFetchingNextPage, isFetching, fetchNextPage, refetch, hasNextPage } = usePhotographs(clickedTags, userLocation?.latitude, userLocation?.longitude);
    const FILTERED_BY_NEWEST = filter === 'newest';
    const PHOTOGRAPHY_LAST_PAGE_PARAMS = data?.pages[data?.pages?.length - 1]

    useEffect(() => {
        let fetching = false;

        const handleScroll = async () => {
            const { scrollHeight, scrollTop, clientHeight } = document.documentElement;

            if (!fetching && !isFetchingNextPage && scrollHeight - scrollTop <= clientHeight * 1.2) {
                fetching = true;
                await fetchNextPage();
                fetching = false;
            }
        };
        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [isFetchingNextPage]);

    return (
        <Header>
            <Box sx={{ background: "white", minHeight: !isMobile && '100vh', pb: !isMobile ? 5 : 19, pt: isMobile ? 5 : 9, position: 'relative' }}>
                {isMobile ? null :
                    <Grid justifyContent={'center'} container sx={{ background: 'rgba(255,255,255,0.97)', pt: 1, pb: 1, zIndex: 1000, width: '100%' }}>
                        <Grid item xs={1} sx={{ borderBottom: '1px solid lightgrey' }} />
                        <Grid item xs={10} pt={1} pb={2} sx={{ borderBottom: '1px solid lightgrey' }}>
                            <PhotographsFilter />
                        </Grid>
                        <Grid item xs={1} sx={{ borderBottom: '1px solid lightgrey' }} />
                        <Grid item xs={1} />
                        <Grid item xs={10} display={'flex'} justifyContent={'space-between'} alignItems={'center'} pt={2} >
                            <Box>
                                <Typography sx={{ pl: 0.5, color: 'dimgray', }}>
                                    {PHOTOGRAPHY_LAST_PAGE_PARAMS?.total ?? '-'} photos found.
                                    {clickedTags?.length !== 0 && ` Filtered by: ${clickedTags.map(tag => tag.name).join(', ')}`}
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'}>
                                <Typography sx={{ px: 1, color: 'dimgray', textWrap: 'nowrap' }}>
                                    Show first:
                                </Typography>
                                <ButtonGroup
                                    disableElevation
                                    variant="contained"
                                    sx={{ '& .MuiButtonGroup-firstButton': { borderColor: 'grey.300', } }}
                                >
                                    <Button size='small' sx={{
                                        borderRadius: 4, textTransform: 'initial', border: '1px solid',
                                        width: '110px', borderColor: 'grey.300',
                                        color: FILTERED_BY_NEWEST ? 'white' : 'grey.900', padding: '3px 8px', fontWeight: 400,
                                        background: FILTERED_BY_NEWEST ? 'black' : '#f0f0f0',
                                        '&:hover': { background: FILTERED_BY_NEWEST ? 'black' : '#f0f0f0', color: FILTERED_BY_NEWEST ? 'white' : 'black' }
                                    }}
                                        onClick={() => setFilter('newest')}>
                                        Recent photos
                                    </Button>
                                    <Button size='small' sx={{
                                        borderRadius: 4, textTransform: 'initial', border: '1px solid',
                                        width: '110px', borderColor: 'grey.300',
                                        color: !FILTERED_BY_NEWEST ? 'white' : 'grey.900', padding: '3px 8px', fontWeight: 400,
                                        background: !FILTERED_BY_NEWEST ? 'black' : '#f0f0f0',
                                        '&:hover': { background: !FILTERED_BY_NEWEST ? 'black' : '#f0f0f0', color: !FILTERED_BY_NEWEST ? 'white' : 'black' }
                                    }}
                                        onClick={() => setFilter('')}>
                                        Nearby
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </Grid>
                        <Grid item xs={1} />
                    </Grid>
                }
                <Grid container sx={{ pt: isMobile ? 3 : 1 }}>
                    <Grid item md={1} xs={false} />

                    <Grid item md={10} xs={12}>
                        {isFetching && !isFetchingNextPage && <Box display="flex" justifyContent="center" alignItems="center">
                            <Grid container direction="row" spacing={isMobile ? 0 : 3} flexWrap="wrap" alignItems="stretch" justifyContent="center">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index) => (
                                    <Grid item key={index} xl={3} md={4} sm={6} xs={11}>
                                        <Box>
                                            <Skeleton variant="rectangular" width="100%" height={230} sx={{ borderRadius: 5 }} />
                                            <Skeleton variant="text" sx={{ mt: 1, ml: '5px' }} />
                                            <Skeleton variant="text" sx={{ mt: 1, ml: '5px', mb: isMobile && 2 }} />
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>}

                        <Grid container justifyContent="center" sx={{ flexWrap: 'nowrap', }}>
                            <Grid item md={12} xs={11}>
                                <Grid container direction="row" spacing={isMobile ? 0 : 3} flexWrap={'wrap'} alignItems="stretch">
                                    {!photoData?.length !== 0 ? photoData?.map((photo) => (
                                        <PhotographyCard key={photo.id} photo={photo} refetch={refetch} />
                                    )) :
                                        <Grid item xs={12}>
                                            <NoResultPage title={"No photos found"} text={"There are currently no photos available."} />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1} xs={false} />
                </Grid>
                {(isFetching || isFetchingNextPage) ?
                    <Box ref={ref} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 2, pb: 4 }}>
                        <CircularProgress disableShrink size={50} />
                    </Box>
                    : null
                }

            </Box>

            {(isMobile && showMobileFilter) ? <MobileFilter mapTags={mapTags} /> : null}

            {isMobile ? null :
                <Box position={'fixed'} bottom={0} backgroundColor={'white'} width={'100%'}>
                    <FooterComponent />
                </Box>
            }

        </Header >
    )
}

export default Home;
