import L from 'leaflet';
import { Icon } from 'leaflet';
import { Tooltip, Marker } from 'react-leaflet';
import { usePhotographsContext } from '../../../contexts/photographs-context';
import { useSettings } from '../../../contexts/settings-context';

const CustomPhotographyMarker = ({ photo, onPhotographyClickedHandler }) => {
  const { isMobile } = useSettings();
  const { isPhotographsDrawerOpened, clickedPhotographyId } = usePhotographsContext();

  const customDesktopMarkerIcon = new Icon({
    iconUrl: `${photo?.featured_image}`,
    iconSize: [46, 46],
    html: `<img src="${photo?.featured_image}" alt='${photo?.title}'></img>`,
    className: (clickedPhotographyId === photo?.id && clickedPhotographyId) ? "clicked-marker-yellow-border" : "photographies",
  });

  const customMobileMarkerIcon = L.divIcon({
    iconUrl: `${photo?.featured_image}`,
    iconSize: [46, 46],
    html: `${clickedPhotographyId === photo?.id && clickedPhotographyId ? `<div class='custom-pin clicked-marker-yellow'></div>`
      : `<div class=${(isPhotographsDrawerOpened) ? 'custom-pin-disabled' : 'custom-pin'}></div>`}`,
    className: clickedPhotographyId === photo?.id && clickedPhotographyId && 'custom-div-icon'
  });

  return (
    <Marker
      position={[photo?.latitude, photo?.longitude]}
      riseOnHover={true}
      icon={customDesktopMarkerIcon}
      eventHandlers={{
        click: () => {
          if (onPhotographyClickedHandler) {
            onPhotographyClickedHandler(photo?.id);
          }
        },
      }}
    >
      {!isMobile && <Tooltip offset={[22, 0]}>{photo?.title}</Tooltip>}
    </Marker>
  );
};

export default CustomPhotographyMarker;