import { useState } from 'react';
import DrawerItem from './drawer-item.component';
import { useAuth } from '../../contexts/auth-context';
import { Drawer, List, Divider, Collapse, ListItem, ListItemText, ListItemAvatar, Avatar, ListItemIcon } from '@mui/material';
import { ExitToApp, FavoriteBorder, ExpandMore, ExpandLess, AutoStories, Help, Mail, Policy, LockOutlined, SettingsOutlined, EditOutlined, PersonAdd } from '@mui/icons-material';

export default function OptionDrawer({ open, setOpen }) {
    const { currentUser } = useAuth();
    const [nestedListOpen, setNestedListOpen] = useState(true);
    const [nestedListProfileOpen, setNestedListProfileOpen] = useState(false);

    return (
        <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
            {currentUser ? (
                <List>
                    <ListItem onClick={() => setNestedListOpen(!nestedListOpen)} style={{ cursor: 'pointer' }}>
                        <ListItemAvatar>
                            <Avatar alt="Avatar Image" src={currentUser.avatar_image}>
                                {currentUser.name.substring(0, 1)}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            {currentUser.name.length > 18 ? currentUser.name.substring(0, 17) + " ..." : currentUser.name}
                        </ListItemText>
                        {nestedListOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Collapse in={nestedListOpen} timeout="auto" unmountOnExit sx={{ cursor: 'pointer' }}>
                        <ListItem className="nested" onClick={() => setNestedListProfileOpen(!nestedListProfileOpen)}>
                            <ListItemIcon>
                                <SettingsOutlined />
                            </ListItemIcon>
                            <ListItemText>Settings</ListItemText>
                            {nestedListProfileOpen ? <ExpandLess /> : < ExpandMore />}
                        </ListItem>

                        <Collapse in={nestedListProfileOpen} timeout="auto" unmountOnExit sx={{ width: '210px' }}>
                            <DrawerItem
                                to="/settings-password"
                                icon={<LockOutlined />}
                                primaryText={'Change password'}
                                onClick={() => setOpen(false)}
                            />
                            <DrawerItem
                                to="/settings"
                                icon={<EditOutlined />}
                                primaryText={'Edit'}
                                onClick={() => setOpen(false)}

                            />
                        </Collapse>

                        <DrawerItem
                            to="/favorites"
                            icon={<FavoriteBorder />}
                            primaryText={'Favorites'}
                            onClick={() => setOpen(false)}
                        />

                        <DrawerItem
                            to="/exit"
                            icon={<ExitToApp />}
                            primaryText={'Exit'}
                            onClick={() => setOpen(false)}
                        />
                    </Collapse>

                    <Divider />

                    <DrawerItem
                        to="/info"
                        icon={<AutoStories />}
                        primaryText={'What is Obektiva'}
                        onClick={() => setOpen(false)}
                    />

                    <DrawerItem
                        to="/privacy-policy"
                        icon={<Policy />}
                        primaryText={'Privacy and policy'}
                        onClick={() => setOpen(false)}
                    />

                    <DrawerItem
                        to="/terms-of-use"
                        icon={<Help />}
                        primaryText={'Terms of Use'}
                        onClick={() => setOpen(false)}
                    />

                    <DrawerItem
                        to="/support"
                        icon={<Mail />}
                        primaryText={'Contacts'}
                        onClick={() => setOpen(false)}
                    />

                    <Divider />
                </List>
            ) : (
                <List>
                    <DrawerItem
                        to="/login"
                        icon={<ExitToApp />}
                        primaryText={'Sign in'}
                        onClick={() => setOpen(false)}
                    />
                    <DrawerItem
                        to="/register"
                        icon={<PersonAdd />}
                        primaryText={'Sign up'}
                        onClick={() => setOpen(false)}
                    />

                    <Divider />

                    <DrawerItem
                        to="/info"
                        icon={<AutoStories />}
                        primaryText={'What is Obektiva'}
                        onClick={() => setOpen(false)}
                    />

                    <DrawerItem
                        to="/privacy-policy"
                        icon={<Policy />}
                        primaryText={'Privacy and policy'}
                        onClick={() => setOpen(false)}
                    />

                    <DrawerItem
                        to="/terms-of-use"
                        icon={<Help />}
                        primaryText={'Terms of Use'}
                        onClick={() => setOpen(false)}
                    />

                    <DrawerItem
                        to="/support"
                        icon={<Mail />}
                        primaryText={'Contacts'}
                        onClick={() => setOpen(false)}
                    />

                    <Divider />
                </List>
            )
            }
        </Drawer >
    );
}