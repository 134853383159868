import React from 'react';
import * as yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { TextField, Button, Box, Typography } from '@mui/material';

const schema = yup.object({
    name: yup.string().required("Name is required!").min(6, "Your name must be at least 6 characters!").max(255, "The name must be maximum 255 characters!"),
    email: yup.string().email('Invalid email address').required('Email is required'),
    password: yup.string().min(6, "Password should be at least 6 characters long!").required('Password is required'),
    password_confirmation: yup.string().required("Confirm password is required!").oneOf([yup.ref("password"), null], "Passwords must match!"),
});

const RegisterForm = ({ onSubmit }) => {
    return (
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Formik
                onSubmit={onSubmit}
                validationSchema={schema}
                initialValues={{
                    name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                }}
            >
                {({ isSubmitting, errors }) => (
                    <Form style={{ width: '100%' }}>
                        <Field
                            fullWidth
                            id="name"
                            type="text"
                            name="name"
                            label="Name"
                            as={TextField}
                            variant="outlined"
                        />
                        {errors?.name ? <Box mb={2}>
                            <ErrorMessage name="name" component="div" style={{ color: 'red', mb: 1 }} />
                        </Box> : <Typography variant="caption" style={{ color: 'green' }}>&nbsp;</Typography>}

                        <Field
                            as={TextField}
                            type="email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            id="email"
                            name="email"
                        />
                        {errors?.email ? <Box mb={2}>
                            <ErrorMessage name="email" component="div" style={{ color: 'red', mb: 1 }} />
                        </Box> : <Typography variant="caption" style={{ color: 'green' }}>&nbsp;</Typography>}

                        <Field
                            as={TextField}
                            type="password"
                            label="Password"
                            variant="outlined"
                            fullWidth
                            id="password"
                            name="password"
                        />
                        {errors?.password ? <Box mb={2}>
                            <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
                        </Box> : <Typography variant="caption" style={{ color: 'green' }}>&nbsp;</Typography>}

                        <Field
                            as={TextField}
                            type="password"
                            label="Confirm Password"
                            variant="outlined"

                            fullWidth
                            id="password_confirmation"
                            name="password_confirmation"
                        />
                        {errors?.password_confirmation ? <Box mb={2}>
                            <ErrorMessage name="password_confirmation" component="div" style={{ color: 'red' }} />
                        </Box> : <Typography variant="caption" style={{ color: 'green' }}>&nbsp;</Typography>}

                        <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            fullWidth
                            sx={{ borderRadius: 15, height: 50 }}
                            disabled={isSubmitting}
                        >
                            Register
                        </Button>
                    </Form >
                )}
            </Formik >
        </Box >
    );
};

export default RegisterForm;