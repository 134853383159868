import React from 'react';
import { IconButton } from '@mui/material';

const CustomIconButton = ({ icon, onClick, active, iconStyle, buttonStyle, ref }) => {
    const buttonStyles = {
        color: iconStyle?.fill ? iconStyle?.fill : 'white',
        backgroundColor: buttonStyle?.backgroundColor ? buttonStyle?.backgroundColor : active ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.6)',
        '&:hover': {
            backgroundColor: buttonStyle?.backgroundColor ? buttonStyle?.backgroundColor : active ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.6)',
        },
        ...buttonStyle,
    };

    const iconWithStyle = React.cloneElement(icon, {
        style: {
            ...iconStyle,
            fontSize: iconStyle?.fontSize
        }
    });

    return (
        <IconButton ref={ref} sx={buttonStyles} aria-label="back" onClick={onClick}>
            {iconWithStyle}
        </IconButton>
    );
};

export default CustomIconButton;
