import { Link } from 'react-router-dom';
import Header from '../../components/header.components';
import { Box, Grid, Divider, Typography } from '@mui/material';
import Sidemenu from '../../components/auth/user/side-menu.component';
import ChangePasswordForm from '../../components/auth/user/change-password-form.component';

export const ChangePassword = () => {
    return (
        <Header>
            <Box sx={{ background: "white", pt: 10 }}>
                <Grid container>
                    <Grid item md={1} xs={false} />

                    <Grid item xs={12} md={10}>

                        <Typography id="back-to-top-anchor" className='breadcrumb-menu-center' variant='h4' gutterBottom>Edit profile</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Link style={{ cursor: 'pointer', color: 'gray', textDecoration: 'none' }} to={'/'}>
                                Home
                            </Link>

                            <Typography color="text.primary"> &nbsp; / &nbsp; Change password</Typography>
                        </Box>

                        <Divider sx={{ mt: 1, mb: 4, }} />

                        <Grid container spacing={2} justifyContent='center'>
                            <Grid item lg={2} xs={false} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
                                <Sidemenu />
                            </Grid>

                            <Grid item xs={12} sm={12} md={9} lg={10}>
                                <ChangePasswordForm />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1} xs={false} />
                </Grid>
            </Box>
        </Header>
    )
}
