import { request } from "../helpers/request";

//* Return details for details place page
export async function getPlaceById(id) {
  try {
    const response = await request.get(`places/${id}`);

    return response.data;
  } catch (error) {
    throw error;
  }
}

//* Return information for places when it clicked on map
export async function getPlaceDetailsOnMap(id) {
  try {
    const response = await request.get(`/places/${id}`);

    return response.data;
  } catch (error) {
    throw error;
  }
}

//* Return a places for map page
export async function getMapPlaces() {
  try {
    const response = await request.get("/places-map/");

    return response.data;
  } catch (error) {
    return error;
  }
}
