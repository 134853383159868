import { Close } from '@mui/icons-material';
import { Chip, Tab, Tabs, SwipeableDrawer, Box, Typography, IconButton, Divider } from '@mui/material';
import { usePhotographsContext } from '../../contexts/photographs-context';

export default function MobileFilter({ mapTags }) {
    const { clickedTags, setClickedTags, showMobileFilter, setShowMobileFilter, filter, setFilter } = usePhotographsContext();
    const FILTERED_BY_NEWEST = filter === 'newest';

    function checkTagHandler(id) {
        if (clickedTags && clickedTags?.map(tag => tag.id).includes(id)) {
            setClickedTags(clickedTags.filter(tag => tag.id !== id))
        }
        else {
            setClickedTags([...clickedTags, ...mapTags.filter(tag => tag.id === id)])
        }
    }

    return (
        <SwipeableDrawer
            className='swipeable-drawer-map'
            anchor="bottom"
            open={showMobileFilter}
            onClose={() => setShowMobileFilter(false)}
            onOpen={() => setShowMobileFilter(true)}
            disableSwipeToOpen={true}
            variant={'persistent'}
            sx={{
                p: 5,
                '.MuiSnackbarContent-message ': { padding: 3, margin: 0, },
                '.MuiPaper-root': {
                    margin: 0,
                    padding: 0,
                    color: 'rgba(0, 0, 0, 0.87)',
                    border: 'unset',
                    height: 'auto',
                },
                '.MuiSvgIcon-root': {
                    fontSize: '2rem'
                }
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'rgba(240, 240, 240, 0.95)', justifyContent: 'space-between',
                    display: 'flex', alignItems: 'center', width: '100%', paddingY: '13px'
                }}
            >
                <Typography variant="caption" sx={{ fontSize: '1.5rem', color: 'dimgray', textAlign: 'center', flex: '1' }}>Find new photos</Typography>

                <IconButton
                    aria-label='filter button badge'
                    onClick={() => { setShowMobileFilter(false) }}
                    sx={{
                        position: 'absolute',
                        right: 3, top: 3,
                        background: 'transparent',
                        p: '3px',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    <Close fontSize='10px' />
                </IconButton>
            </Box>

            <>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', pb: 1 }}>
                    <Typography variant="caption" sx={{ fontSize: '16px', color: 'dimgray', px: 2, pt: 1 }}>Filter by:</Typography>
                    <Box sx={{ pl: 1 }}>
                        <Chip sx={{
                            boxShadow: 'unset', m: '3px !important',
                            backgroundColor: !FILTERED_BY_NEWEST ? '#575757' : '#f0f0f0',
                            color: !FILTERED_BY_NEWEST ? 'white' : 'black',
                            '&:hover': {
                                backgroundColor: !FILTERED_BY_NEWEST ? '#575757' : '#f0f0f0',
                                color: !FILTERED_BY_NEWEST ? 'white' : 'black',
                            }
                        }} size="medium" label={'Closest'}
                            onClick={() => { setFilter('') }}
                            className={'chip-not-clicked'} />
                        <Chip sx={{
                            boxShadow: 'unset', m: '3px !important',
                            backgroundColor: FILTERED_BY_NEWEST ? '#575757' : '#f0f0f0',
                            color: FILTERED_BY_NEWEST ? 'white' : 'black',
                            '&:hover': {
                                backgroundColor: FILTERED_BY_NEWEST ? '#575757' : '#f0f0f0',
                                color: FILTERED_BY_NEWEST ? 'white' : 'black',
                            }
                        }} size="medium" label={'Newest'}
                            onClick={() => { setFilter('newest') }}
                            className={'chip-not-clicked'} />
                    </Box>
                </Box>
                <Divider sx={{ mb: 1 }} />
                <Box>
                    <Typography variant="caption" sx={{ fontSize: '16px', color: 'dimgray', px: 2, pb: 1, pt: 2 }}>I want to visit:</Typography>

                    <Tabs
                        className='filter-drawer'
                        value={false}
                        allowScrollButtonsMobile
                        variant="scrollable"
                        sx={{
                            minHeight: 'unset',
                            '&.filter-drawer .MuiTabs-scrollButtons.Mui-disabled': {
                                opacity: 1,
                                background: 'none',
                                color: 'lightgray'
                            },
                            pb: 1
                        }}
                    >
                        {mapTags?.map(tag => (
                            <Tab
                                disableRipple
                                sx={{ p: 0, maxWidth: 'fit-content', minWidth: 'fit-content', minHeight: 'fit-content', textTransform: 'unset', fontWeight: 400, }}
                                key={tag.id}
                                label={<Chip sx={{
                                    boxShadow: 'unset', m: '3px !important',
                                    backgroundColor: clickedTags && clickedTags?.some(tagItem => tagItem.id === tag.id) ? '#575757' : '#f0f0f0',
                                    color: clickedTags && clickedTags?.some(tagItem => tagItem.id === tag.id) ? 'white' : 'black',
                                    '&:hover': {
                                        backgroundColor: clickedTags && clickedTags?.some(tagItem => tagItem.id === tag.id) ? '#575757' : '#f0f0f0',
                                        color: clickedTags && clickedTags?.some(tagItem => tagItem.id === tag.id) ? 'white' : 'black',
                                    }
                                }} size="medium" label={tag?.name}
                                    onClick={() => { checkTagHandler(tag?.id); }}
                                    className={'chip-not-clicked'} />
                                }
                            />
                        ))}
                    </Tabs>
                </Box>
            </>
        </SwipeableDrawer >
    );
}