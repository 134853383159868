import "./App.css";
import theme from "./theme";
import Navigation from "./navigation/navigation";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./contexts/auth-context";
import { SettingsProvider } from "./contexts/settings-context";
import { PhotographyProvider } from "./contexts/photographs-context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 0,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <SettingsProvider>
            <BrowserRouter>
              <PhotographyProvider>
                <Navigation />
              </PhotographyProvider>
            </BrowserRouter>
          </SettingsProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
