import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { Box, Typography, TextField, Button } from '@mui/material';

const validationSchema = yup.object({
    password: yup.string().min(6, "Password should be at least 6 characters long!").required('Password is required'),
    password_confirmation: yup.string().required("Confirm password is required!").oneOf([yup.ref("password"), null], "Passwords must match!"),
});

const ResetPasswordForm = ({ onSubmit, isMobile }) => {
    return (
        <Formik
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            initialValues={{
                password: '',
                password_confirmation: '',
            }}
        >
            {({ isSubmitting, errors }) => (
                <Form style={{ width: isMobile ? '90%' : '70%', textAlign: 'center' }}>
                    <Field
                        as={TextField}
                        type="password"
                        label="Password"
                        variant="outlined"
                        fullWidth
                        id="password"
                        name="password"
                    />
                    {errors?.password ? <Box mb={2}>
                        <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
                    </Box> : <Typography variant="caption">&nbsp;</Typography>}

                    <Field
                        as={TextField}
                        type="password"
                        label="Confirm Password"
                        variant="outlined"
                        fullWidth
                        id="password_confirmation"
                        name="password_confirmation"
                    />
                    {errors?.password_confirmation ? <Box mb={2}>
                        <ErrorMessage name="password_confirmation" component="div" style={{ color: 'red' }} />
                    </Box> : <Typography variant="caption">&nbsp;</Typography>}

                    <Box sx={{ mb: 3 }}>
                        <Button type="submit" variant="contained" color="success" fullWidth sx={{ borderRadius: 15, height: 50 }}>
                            Save new password
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}

export default ResetPasswordForm;