import { useNavigate } from "react-router-dom";
import { useGetPhotoMap } from "../../hooks/usePhotos";
import { Close, ArrowForward } from "@mui/icons-material";
import { usePhotographsContext } from "../../contexts/photographs-context";
import { Box, CardMedia, Typography, Snackbar, IconButton, Skeleton, Button } from "@mui/material";
import { useState } from "react";

export default function PhotographyContainer({ id }) {
    const navigate = useNavigate();
    const { data, isFetched, } = useGetPhotoMap(id);
    const { isPhotographsDrawerOpened, setIsPhotographsDrawerOpened, setClickedPhotographyId } = usePhotographsContext();

    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const style = {
        container: {
            maxWidth: 320,
            minWidth: 280
        },
        numberOfLines: {
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
        }
    };

    const detailsClickHandler = () => {
        setIsPhotographsDrawerOpened(false);
        setClickedPhotographyId();
        navigate(`/photography/${id}`);
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            disableWindowBlurListener={true}
            open={isPhotographsDrawerOpened}
            sx={{
                '.MuiSnackbarContent-message ': {
                    padding: 0,
                },
                '.MuiPaper-root': {
                    backgroundColor: '#fff', color: 'rgba(0, 0, 0, 0.87)', padding: '10px',
                }
            }}
            message={
                <>
                    {isFetched ?
                        <Box display={'flex'}>
                            <Box sx={{ display: 'flex', paddingRight: 1, }}>
                                {data?.featured_image &&
                                    <CardMedia
                                        sx={{ display: !isImageLoaded ? 'none' : 'initial', borderRadius: 1, width: 180, height: 130, cursor: 'pointer' }}
                                        component="img"
                                        onLoad={(e) => { setIsImageLoaded(true); }}
                                        onClick={() => detailsClickHandler()}
                                        image={data?.featured_image}
                                        alt={data?.title}
                                    />
                                }
                                {((data?.featured_image && !isImageLoaded) || !data?.featured_image) &&
                                    <Skeleton
                                        variant="rectangular" width={180} height={130}
                                        sx={{ borderRadius: 1, cursor: 'pointer' }}
                                        onClick={() => detailsClickHandler()}
                                    />
                                }
                            </Box>
                            <Box display={"flex"} justifyContent={'space-between'} flexDirection={'column'} height={'auto'} width={'100%'} sx={{ ...style.container, }}>
                                <Box px={1} pr={2} sx={{ ...style.container, }}>
                                    <IconButton
                                        size="small"
                                        aria-label="close"
                                        color="inherit"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsPhotographsDrawerOpened(false);
                                            setClickedPhotographyId();
                                        }}
                                        sx={{ p: '2px', position: 'absolute', top: 2, right: 2, zIndex: 999 }}
                                    >
                                        <Close fontSize="small" />
                                    </IconButton>
                                    <Typography variant="h4"
                                        onClick={() => detailsClickHandler()}
                                        sx={{
                                            ...style.container, fontSize: { xs: 18, lg: 19 },
                                            ...style.numberOfLines, WebkitLineClamp: 2, cursor: 'pointer'
                                        }}  >
                                        {data?.title}
                                    </Typography>
                                    <Typography variant="h4"
                                        onClick={() => detailsClickHandler()}
                                        sx={{
                                            ...style.container, fontSize: { xs: 14, lg: 15 }, color: 'grey',
                                            ...style.numberOfLines, WebkitLineClamp: 1, cursor: 'pointer'
                                        }}  >
                                        {data?.subtitle}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2 }}>
                                    <Button
                                        onClick={() => detailsClickHandler()}
                                        endIcon={<ArrowForward />} variant='contained' color="success"
                                        sx={{
                                            borderRadius: 15, height: 'fit-content', fontSize: 13,
                                            textTransform: 'unset', boxShadow: 'none'
                                        }}>
                                        Details
                                    </Button>
                                </Box>
                            </Box>
                        </Box> :
                        <Box display="flex">
                            <Skeleton variant="rectangular" width={180} height={130} sx={{ borderRadius: 1, mr: 1 }} />
                            <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ minWidth: 0 }}>
                                <Box sx={{ ...style.container, minWidth: 272, mb: 1, pr: 2 }}>
                                    <Skeleton variant="text" height={48} sx={{ transformOrigin: '0 10%' }} />
                                    <Skeleton variant="text" height={18} sx={{ mt: -1 }} />
                                    <Skeleton variant="rectangular" width={100} height={30} sx={{
                                        position: 'absolute', bottom: 10, right: 10, borderRadius: 15
                                    }} />
                                </Box>
                                <IconButton onClick={() => {
                                    setIsPhotographsDrawerOpened(false);
                                    setClickedPhotographyId();
                                }}
                                    size="small" sx={{ p: '2px', position: 'absolute', top: 2, right: 2 }}>
                                    <Close fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>}
                </>
            }
        />
    )
}