import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TuneOutlined } from "@mui/icons-material";
import { useSettings } from '../../contexts/settings-context';
import { Box, IconButton, Chip, Badge, Grow, Tabs, Tab } from "@mui/material";
import { usePhotographsContext } from '../../contexts/photographs-context';

const PhotographsFilter = () => {
    const location = useLocation();
    const { mapTags, isMobile } = useSettings();
    const { clickedTags, setClickedTags } = usePhotographsContext();

    const onMapScreen = location.pathname === '/map';
    const [show, setShow] = useState(true);

    function checkTagHandler(id) {
        if (clickedTags && clickedTags?.map(tag => tag.id).includes(id)) {
            setClickedTags(clickedTags.filter(tag => tag.id !== id))
        }
        else {
            setClickedTags([...clickedTags, ...mapTags.filter(tag => tag.id === id)])
        }
    }

    return (
        <>
            {onMapScreen ?
                <Box display="flex" alignItems={'center'}>
                    {show ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-end',
                                position: 'fixed',
                                bottom: '5px',
                                right: '50px',
                            }}
                        >
                            {mapTags?.map(tag => (
                                <Grow key={tag?.id} in={!show}>
                                    <>
                                        <Chip
                                            label={tag?.name}
                                            transition={Grow}
                                            onClick={() => checkTagHandler(tag.id)}
                                            sx={{
                                                m: '3px',
                                                height: '25px',
                                                cursor: 'pointer',
                                                boxShadow: 'unset',
                                                border: '0.25px solid lightgrey',
                                                backgroundColor: clickedTags && clickedTags?.some(tagItem => tagItem.id === tag.id) ? '#575757' : 'white',
                                                color: clickedTags && clickedTags?.some(tagItem => tagItem.id === tag.id) ? 'white' : 'black',
                                                '&:hover': {
                                                    backgroundColor: clickedTags && clickedTags?.some(tagItem => tagItem.id === tag.id) ? '#575757' : 'white',
                                                    color: clickedTags && clickedTags?.some(tagItem => tagItem.id === tag.id) ? 'white' : 'black',
                                                }
                                            }}
                                        />
                                    </>
                                </Grow>
                            ))}
                        </Box>
                    ) : null}
                    {mapTags?.length > 0 && (
                        <Badge
                            badgeContent={clickedTags?.length}
                            color="success"
                            sx={{
                                backgroundColor: ((show || clickedTags?.length !== 0) ? '#575757' : 'white'),
                                borderRadius: 1,
                                borderWidth: 1,
                                '&:hover': {},
                            }}
                        >
                            <IconButton
                                size="large"
                                onClick={() => setShow((prev) => !prev)}
                                sx={{
                                    background: (show || clickedTags?.length !== 0) && '#575757',
                                    p: '3px', '&:hover': {
                                        backgroundColor: clickedTags?.length !== 0 ? '#575757' : (show ? '#575757' : 'white'),
                                    },
                                }}
                            >
                                <TuneOutlined sx={{ color: (show || clickedTags?.length !== 0) && 'white' }} />
                            </IconButton>
                        </Badge>
                    )
                    }
                </Box >
                :
                <Box display="flex" justifyContent={'space-between'} alignItems={'center'} height={'100%'}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'flex-end',
                            position: 'unset',
                            bottom: 'unset',
                            right: 'unset',
                            width: '100%',
                            justifyContent: 'center',
                        }}
                    >
                        <Tabs
                            variant="scrollable"
                            value={false}
                            scrollButtons={true}
                            sx={{
                                display: 'flex', alignItems: 'center', minHeight: 'unset',
                                '& .MuiButtonBase-root.MuiTabScrollButton-root': { opacity: 1, borderRadius: 15, border: '1px solid #f0f0f0', width: 'unset', p: 0.7, mx: 0.5 },
                                // '& .MuiTabs-scroller .MuiTabs-flexContainer': { justifyContent: 'center' },
                            }}
                        >
                            {mapTags?.map(tag => (
                                <Tab
                                    key={tag?.id}
                                    label={tag?.name}
                                    transition={Grow}
                                    onClick={() => checkTagHandler(tag.id)}
                                    sx={{
                                        borderRadius: 15, fontSize: 13, fontWeight: 400, py: 1, minHeight: 'unset',
                                        textTransform: 'none',
                                        mx: '3px',
                                        px: 2,
                                        opacity: 1,
                                        // height: '25px',
                                        cursor: 'pointer',
                                        boxShadow: 'unset',
                                        backgroundColor: clickedTags && clickedTags?.some(tagItem => tagItem.id === tag.id) ? 'black' : '#f0f0f0',
                                        color: clickedTags && clickedTags?.some(tagItem => tagItem.id === tag.id) ? 'white' : 'black',
                                        '&:hover': {
                                            backgroundColor: clickedTags && clickedTags?.some(tagItem => tagItem.id === tag.id) ? 'black' : '#f0f0f0',
                                            color: clickedTags && clickedTags?.some(tagItem => tagItem.id === tag.id) ? 'white' : 'black',
                                        }
                                    }}
                                />
                            ))}
                        </Tabs>
                    </Box>
                </Box >
            }
        </>

    );
};

export default PhotographsFilter;
