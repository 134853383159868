import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { TextField, Button, Box, Typography } from '@mui/material';

const schema = yup.object({
    email: yup.string().email('Invalid email address').required('Email is required'),
    password: yup.string().required('Password is required'),
});

const LoginForm = ({ onSubmit, navigate }) => {
    const initialValues = {
        email: '',
        password: '',
    };

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Formik
                onSubmit={onSubmit}
                validationSchema={schema}
                initialValues={initialValues}
            >
                {({ isSubmitting, errors }) => (
                    <Form style={{ width: '100%', }}>
                        <Field
                            as={TextField}
                            type="text"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            id="email"
                            name="email"
                        />
                        {errors?.email ? <Box mb={2}>
                            <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
                        </Box> : <Typography variant="caption" style={{ color: 'green' }}>&nbsp;</Typography>}

                        <Field
                            as={TextField}
                            type="password"
                            label="Password"
                            variant="outlined"
                            fullWidth
                            id="password"
                            name="password"
                        />
                        {errors?.password ? <Box mb={2}>
                            <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
                        </Box> : <Typography variant="caption" style={{ color: 'green' }}>&nbsp;</Typography>}

                        <Button type="submit" variant="contained" disabled={isSubmitting} color="success" fullWidth sx={{ borderRadius: 15, height: 50 }}>
                            Login
                        </Button>

                        <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Link to={'/forgotten-password'} style={{ cursor: 'pointer', textUnderlineOffset: 5, color: 'black', textAlign: 'center' }}>
                                Forgotten password?
                            </Link>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default LoginForm;