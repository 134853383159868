import { useEffect, useRef } from 'react';
import { Typography, Box, CardMedia } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useSettings } from '../../contexts/settings-context';

const IMAGE_CONTAINER = (clickedPlaceId, place, isMobile) => {
    return { width: clickedPlaceId === place?.id ? 'calc(100% - 6px)' : '100%', }
};

const PLACE_CONTAINER = (clickedPlaceId, place, isMobile) => {
    return {
        width: clickedPlaceId === place?.id ? (isMobile ? 'calc(160px - 6px)' : 'calc(160px - 6px)') : (isMobile ? '160px' : '100px'),
        maxWidth: isMobile ? '160px' : '100px', minWidth: isMobile ? '160px' : '100px',
    }
};

export default function PlacesList({ places, onPhotographyClickedHandler, clickedPlaceId }) {
    const scrollRef = useRef(null);
    const { isMobile } = useSettings();

    useEffect(() => {
        if (clickedPlaceId && scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [clickedPlaceId]);

    return (
        <Box className='scroll-container' sx={{ width: "100%", overflow: "auto", display: "flex", py: 1 }}>
            {places?.map((place) => (
                <Box
                    key={place?.id}
                    ref={clickedPlaceId === place.id ? scrollRef : null}
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.85)', borderRadius: 1,
                        height: 'auto',
                        ...PLACE_CONTAINER(clickedPlaceId, place, isMobile), cursor: 'pointer', p: 1, mx: 1, px: clickedPlaceId === place?.id ? '5px' : 1,
                        display: 'flex', alignItems: "stretch", flexDirection: 'column',
                        border: clickedPlaceId === place?.id && '3px solid #008EB3'
                    }}
                    onClick={() => onPhotographyClickedHandler(place)}
                >
                    {place?.featured_image ? (
                        <CardMedia
                            sx={{
                                ...IMAGE_CONTAINER(clickedPlaceId, place, isMobile),
                                height: isMobile ? (clickedPlaceId === place?.id ? 84 : 90) : (clickedPlaceId === place?.id ? 54 : 60),
                                borderRadius: 1,
                            }}
                            image={place?.featured_image}
                            alt={place?.title}
                        />
                    ) : (
                        <Box sx={{
                            ...IMAGE_CONTAINER(clickedPlaceId, place, isMobile),
                            height: isMobile ? (clickedPlaceId === place?.id ? 84 : 90) : (clickedPlaceId === place?.id ? 54 : 60),
                            minWidth: 60, backgroundColor: '#E5E4E2',
                            borderRadius: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }}>
                            <AccountBalanceIcon fontSize='medium' />
                        </Box>
                    )}

                    {/* <Typography fontSize={"10px"} variant="body2" noWrap={false}>{place?.title}</Typography> */}

                    <Typography
                        variant="body2"
                        fontSize={isMobile ? "15px" : "10px"}
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            pt: '4px'
                        }}
                    >
                        {place?.title}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
}
