import './terms-of-use.css'
import { Box } from '@mui/material';
import Header from "../../components/header.components";
import { useSettings } from "../../contexts/settings-context";
import FooterComponent from '../../components/footer.component';
import BackButton from '../../components/ui/back-button.component';

const TermsOfUse = () => {
    const { siteInfo, isMobile } = useSettings();

    function createMarkup() {
        return { __html: siteInfo?.terms_of_use };
    }

    return (
        <Header>
            <Box className="terms-of-use" sx={{ minHeight: `calc(100vh - 64px)` }}>
                {isMobile ?
                    <BackButton sx={{ backgroundColor: '#F1F1F1', position: 'fixed', top: 10, left: 10, zIndex: 1000, }} />
                    : null}
                <Box pt={isMobile ? 0 : 10}>
                    <Box dangerouslySetInnerHTML={createMarkup()} />
                </Box>
            </Box>

            <FooterComponent />
        </Header>
    )
}

export default TermsOfUse