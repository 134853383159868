import React from 'react'
import L from 'leaflet';
import { Marker } from "react-leaflet";

const PlaceMarkerComponent = ({ place, clickedPlaceId, onPlaceClickedHandler }) => {
    return (
        <Marker
            key={place?.id}
            eventHandlers={{
                click: () => {
                    onPlaceClickedHandler(place?.id);
                },
            }} riseOnHover={true} position={[place?.latitude, place?.longitude]}
            icon={L.divIcon({
                iconSize: [22, 22],
                html: `${clickedPlaceId === place?.id && clickedPlaceId ? `<div class='custom-pin-map-places clicked-marker-map-blue'></div>`
                    : `<div class='custom-pin-map-places'></div>`}`,
                className: clickedPlaceId === place?.id && clickedPlaceId && 'custom-div-icon'
            })}
        />
    )
}

export default PlaceMarkerComponent