import { useNavigate } from 'react-router-dom';
import { Box, Typography, Grid } from '@mui/material';
import { useAuth } from '../../contexts/auth-context';
import { registerUser } from '../../services/auth.services';
import BackButton from '../../components/ui/back-button.component';
import MobileRegisterForm from '../../components/auth/mobile-register-form.component';

const MobileRegister = () => {
    const navigate = useNavigate();
    const { changeCurrentUser, setErrorMessage, setIsLoaded } = useAuth();

    async function handleRegister(values) {
        setIsLoaded(true);
        setErrorMessage('');
        const result = await registerUser(values);

        if (result?.status === 200) {
            await changeCurrentUser(result?.data?.token);
            navigate('/', { replace: true });
        } else {
            setErrorMessage(result);
        }
        setIsLoaded(false);
    }

    return (
        <Box sx={{ position: 'relative', height: '100vh' }}>
            <BackButton sx={{ backgroundColor: '#F1F1F1', position: 'absolute', top: 0, left: 10, zIndex: 1000, }} />

            <Typography sx={{ color: 'black', textAlign: 'center', marginTop: 2 }} variant='h4'>Sign Up</Typography>
            <Grid container justifyContent="center" my={3}>
                <Grid item xs={10}>
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} mt={6}>
                        <Box textAlign={'center'} mb={2}>
                            <Typography variant='subtitle2' sx={{ color: '#868686', pb: 1 }}>Mark the places you like and share photos from the places you visited.</Typography>
                        </Box>
                        <MobileRegisterForm onSubmit={handleRegister} navigate={navigate} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MobileRegister;
