import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/auth-context';
import Header from '../../components/header.components';
import { registerUser } from '../../services/auth.services';
import { useRandomImage } from '../../hooks/useRandomImage';
import { useSettings } from '../../contexts/settings-context';
import { Grid, Typography, Alert, Box } from '@mui/material';
import RegisterForm from '../../components/auth/register-form.component';

const RegisterPage = () => {
    const navigate = useNavigate();
    const { siteInfo } = useSettings();
    const { data, refetch } = useRandomImage();
    const { changeCurrentUser, setErrorMessage, errorMessage, setIsLoaded } = useAuth();

    useEffect(() => {
        refetch();
    }, [refetch]);

    async function handleRegister(values) {
        setIsLoaded(true);
        setErrorMessage('');
        const result = await registerUser(values);

        if (result?.status === 200) {
            await changeCurrentUser(result?.data?.token);
            navigate('/', { replace: true });
        } else {
            setErrorMessage(result);
        }
        setIsLoaded(false);
    }

    return (
        <Header>
            <Box style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${data?.image})`, backgroundSize: 'cover' }}>
                <Grid container justifyContent="center" mt={0} mb={0} spacing={0} alignItems="center" >
                    <Grid item xs={12} sm={8} md={8} lg={6} sx={{ background: '#f0f0f0', borderRadius: 5 }} p={5}>
                        <Grid item xs={10} sm={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexWrap: 'wrap' }}>
                            <Typography variant="body2">
                                Already have an account? &nbsp;
                                <Link to={'/login'} style={{ color: 'green', cursor: 'pointer', textDecoration: 'underline', }}>Sign in here!</Link>
                            </Typography>
                        </Grid>

                        <Grid container mt={3} display={'flex'} justifyContent={'space-between'}>
                            <Grid item xs={12} sm={12} md={4}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', md: 'left' }, }}>
                                    <img src={siteInfo?.logo} alt="logo" width='40' height='40' style={{ cursor: 'pointer', }} onClick={() => navigate('/')} />
                                    <Typography variant='h5' sx={{ textTransform: 'uppercase', fontSize: { xs: '1rem', md: '1.2rem', lg: '1.3rem' } }}>&nbsp; New profile</Typography>
                                </Box>

                                <Box>
                                    <Typography variant="body2" gutterBottom fontSize={"1.5rem"} sx={{ pb: 1, pt: 5, display: { xs: 'none', md: 'block' }, }}>
                                        Like and Share
                                    </Typography>

                                    <Typography variant='subtitle2' sx={{ color: '#868686', marginY: { xs: 2, md: 3 }, textAlign: { xs: 'center', md: 'left' }, fontSize: 14 }}>
                                        Mark the places you like and share photos from the places you visited.
                                    </Typography>
                                </Box>

                                <Typography variant='subtitle2' sx={{ fontSize: 14, display: { xs: 'none', md: 'block' }, }}>
                                    By registering, you confirm that you agree to our &nbsp;
                                    <Link to={"/privacy-policy"} style={{ color: 'green', cursor: 'pointer', }}>Privacy Policy.</Link>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={7}>
                                {errorMessage ? <Alert sx={{ mb: 2 }} severity="error">
                                    {errorMessage}
                                </Alert> : null}
                                <RegisterForm onSubmit={handleRegister} />
                            </Grid>

                            <Box sx={{ position: 'absolute', bottom: 5, right: 5, background: '#0000008e', color: 'white', p: 3, borderRadius: 3 }}>
                                <Typography>{data?.title}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box >
        </Header >
    );
};

export default RegisterPage;