import { Box, Typography } from '@mui/material';
import Header from '../../components/header.components';
import MobileChangePasswordForm from '../../components/auth/user/mobile-change-password.form.component';
import BackButton from '../../components/ui/back-button.component';

export const MobileChangePassword = () => {
    return (
        <Header>
            <BackButton sx={{
                backgroundColor: '#F1F1F1',
                position: 'absolute',
                top: 10,
                left: 10,
                zIndex: 1000,
            }} />
            <Typography sx={{ color: 'black', textAlign: 'center', marginTop: 2 }} variant='h5'>Change Password</Typography>

            <Box display={'flex'} justifyContent={'center'} mt={5}>
                <MobileChangePasswordForm />
            </Box>
        </Header >
    )
}
