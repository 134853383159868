import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth-context';
import { loginUser } from '../../services/auth.services';
import { Box, Typography, Grid } from '@mui/material';
import MobileLoginForm from '../../components/auth/mobile-login-form.component';
import BackButton from '../../components/ui/back-button.component';

const MobileLoginPage = () => {
    const navigate = useNavigate();
    const { setIsLoaded, setErrorMessage, changeCurrentUser } = useAuth();

    async function handleLogin(values) {
        setIsLoaded(true);
        setErrorMessage('');
        const result = await loginUser(values);

        if (result?.status === 200) {
            await changeCurrentUser(result?.data?.token);
            navigate('/', { replace: true });
        } else {
            setErrorMessage(result);
        }
        setIsLoaded(false);
    }

    return (
        <Box sx={{ position: 'relative', }}>
            <BackButton sx={{ backgroundColor: '#F1F1F1', position: 'absolute', top: 0, left: 10, zIndex: 1000, }} />

            <Typography sx={{ color: 'black', textAlign: 'center', marginTop: 2 }} variant='h4'>Sign In</Typography>

            <Grid container justifyContent="center" my={5}>
                <Grid item xs={10}>
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} mt={10}>
                        <Box my={2} textAlign={'center'}>
                            <Typography variant="h5" sx={{ pb: 1 }}>Like and Share</Typography>
                            <Typography variant='subtitle2' sx={{ color: '#868686' }}>Mark the places you like and share photos from the places you visited.</Typography>
                        </Box>

                        <MobileLoginForm onSubmit={handleLogin} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MobileLoginPage;
