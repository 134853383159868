import React from 'react'
import { Box, Typography } from '@mui/material';
import Header from '../components/header.components';
import { useSettings } from '../contexts/settings-context';

const PageNotFound = () => {
    const { isMobile } = useSettings();
    return (
        <Header>
            <Box sx={{ display: 'flex', justifyContent: 'center', pb: !isMobile && 10, pt: isMobile ? 5 : 19, }}>
                <Box>
                    <img
                        height={450}
                        className="error-img"
                        src="https://i.imgur.com/Q2BAOd2.png"
                        alt="error"
                    />
                    <Typography variant='h6' color='#2f8e89' textAlign={'center'}>Page Not Found!</Typography>
                </Box>
            </Box>
        </Header>
    )
}

export default PageNotFound