import ScrollToTop from "./scroll-to-top";
import { useAuth } from "../contexts/auth-context";
import { Box, CircularProgress } from '@mui/material';
import { useSettings } from "../contexts/settings-context";

///* Web Pages Imports
import Home from "../pages/Home";
import HandleAuth from "./handle-auth";
import LoginPage from "../pages/auth/Login";
import Contacts from "../pages/info/Contacts";
import Favorites from "../pages/user/Favorites";
import { Route, Routes } from "react-router-dom";
import TermsOfUse from "../pages/info/TermsOfUse";
import { Settings } from "../pages/user/Settings";
import RegisterPage from "../pages/auth/Register";
import MapComponent from "../components/map/map.component";
import PhotographyDetails from "../pages/PhotographyDetails";
import { ChangePassword } from "../pages/user/ChangePassword";
import PrivacyAndPolicy from "../pages/info/PrivacyAndPolicy";
import ForgottenPassword from "../pages/auth/ForgottenPassword";
import Notification from "../components/notification-component";
import PageNotFound from "../pages/PageNotFound";
import WhatIsObektiva from "../pages/info/WhatIsObektiva";
import ResetPassword from "../pages/auth/ResetPassword";

//* Mobile Pages Imports
import MobileLoginPage from "../pages/auth/MobileLogin";
import MobileRegister from "../pages/auth/MobileRegister";
import { MobileSettings } from "../pages/user/MobileSettings";
import { MobileChangePassword } from "../pages/user/MobileChangePassword";

const Navigation = () => {
    const { isMobile } = useSettings();
    const { isLoaded, successMessage, errorMessage, setErrorMessage, setSuccessMessage } = useAuth()

    if (isLoaded) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height={'100vh'}>
                <CircularProgress size={50} />
            </Box>
        )
    }

    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={isMobile ? <MobileLoginPage /> : <LoginPage />} />
                <Route exact path="/map" element={<MapComponent />} />
                <Route path="/support" element={<Contacts />} />
                <Route path="/register" element={isMobile ? <MobileRegister /> : <RegisterPage />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/photography/:id" element={<PhotographyDetails />} />
                <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
                <Route path="/info" element={<WhatIsObektiva />} />
                <Route path="/forgotten-password" element={<ForgottenPassword />} />
                <Route path='/reset-password/:token/:email' element={<ResetPassword />} />
                <Route element={<HandleAuth />}>
                    <Route path="/favorites" element={<Favorites />} />
                    <Route path="/settings" element={isMobile ? <MobileSettings /> : <Settings />} />
                    <Route path="/settings-password" element={isMobile ? <MobileChangePassword /> : <ChangePassword />} />
                </Route>

                <Route path="*" element={<PageNotFound />} />
            </Routes>
            <Notification
                isOpen={successMessage !== '' || errorMessage !== ''}
                handleClick={() => {
                    setSuccessMessage('');
                    setErrorMessage('');
                }}
                success={successMessage}
                info={errorMessage}
                statement={successMessage}
                vertical="bottom"
                horizontal="right"
                duration={5000}
            />
        </>
    );
};

export default Navigation;