import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { TextField, Button, Box, Grid, Typography } from '@mui/material';

const schema = yup.object({
    name: yup.string().required("Name is required!").min(6, "Your name must be at least 6 characters!").max(255, "The name must be maximum 255 characters!"),
    email: yup.string().email('Invalid email address').required('Email is required'),
    password: yup.string().min(6, "Password should be at least 6 characters long!").required('Password is required'),
    password_confirmation: yup.string().required("Confirm password is required!").oneOf([yup.ref("password"), null], "Passwords must match!"),
});

const MobileRegisterForm = ({ onSubmit, navigate }) => {
    return (
        <Formik
            onSubmit={onSubmit}
            validationSchema={schema}
            initialValues={{ name: '', email: '', password: '', password_confirmation: '', }}>
            {({ isSubmitting, errors }) => (
                <Form style={{ width: '100%' }}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Field
                            fullWidth
                            id="name"
                            name="name"
                            as={TextField}
                            variant="outlined"
                            label="Name"
                            InputLabelProps={{ shrink: true }}
                        />

                        {errors?.name ? <Box mb={2}>
                            <ErrorMessage name="name" component="div" style={{ color: 'red' }} />
                        </Box> : <Typography variant="caption" style={{ color: 'green' }}>&nbsp;</Typography>}
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <Field
                            fullWidth
                            id="email"
                            name="email"
                            type="email"
                            as={TextField}
                            variant="outlined"
                            label="Email"
                            InputLabelProps={{ shrink: true }}
                        />
                        {errors?.email ? <Box mb={2}>
                            <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
                        </Box> : <Typography variant="caption" style={{ color: 'green' }}>&nbsp;</Typography>}

                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <Field
                            as={TextField}
                            type="password"
                            label="Password"
                            variant="outlined"
                            fullWidth
                            id="password"
                            name="password"
                            InputLabelProps={{ shrink: true }}
                        />
                        {errors?.password ? <Box mb={2}>
                            <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
                        </Box> : <Typography variant="caption" style={{ color: 'green' }}>&nbsp;</Typography>}
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <Field
                            as={TextField}
                            type="password"
                            label='Confirm Password'
                            variant="outlined"
                            fullWidth
                            id="password_confirmation"
                            InputLabelProps={{ shrink: true }}
                            name="password_confirmation"
                        />
                        {errors?.password_confirmation ? <Box mb={2}>
                            <ErrorMessage name="password_confirmation" component="div" style={{ color: 'red' }} />
                        </Box> : <Typography variant="caption" style={{ color: 'green' }}>&nbsp;</Typography>}
                    </Grid>

                    <Button type="submit" variant="contained" color="success" disabled={isSubmitting} fullWidth sx={{ borderRadius: 15, height: 50 }}>
                        Sign Up
                    </Button>

                    <Box mt={2} display={'flex'} flexDirection={'column'} textAlign={'center'} justifyContent={'center'}>
                        <Typography variant="body2">
                            Already have an account? &nbsp;
                            <Link to={'/login'} style={{ color: 'green', textDecoration: 'underline', }}>Sign in here!</Link>
                        </Typography>

                        <Typography variant='caption' mt={3}>
                            By registering, you confirm that you agree to our &nbsp;
                            <Link to={'/privacy-policy'} style={{ color: 'green' }}>Privacy Policy.</Link >
                        </Typography>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default MobileRegisterForm;