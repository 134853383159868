import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import {
  getMapPhotos,
  getAllPhotos,
  getPhotoOnMapById,
  getPhotoById,
} from "../services/photos.services";
import { getFavoritesPhotos } from "../services/user.services";
import { useAuth } from "../contexts/auth-context";
import { useEffect, useRef } from "react";
import { usePhotographsContext } from "../contexts/photographs-context";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSettings } from "../contexts/settings-context";

const updateUrlWithTags = (tags, navigate, location) => {
  const searchParams = new URLSearchParams(location.search);
  if (tags.length > 0) {
    const tagNames = tags.map(tag => tag.name).join(',');
    searchParams.set('tags', tagNames);
  } else {
    searchParams.delete('tags');
  }
  navigate({
    pathname: location.pathname,
    search: searchParams.toString(),
  }, { replace: true });
};

const setTagsOnLoad = (mapTags, setClickedTags, location) => {
  const searchParams = new URLSearchParams(location.search);
  const tags = searchParams.get('tags');
  if (tags) {
    const tagsArray = tags.split(',');
    const filteredTags = tagsArray.map(tagName =>
      mapTags.find(tag => tag.name === tagName)
    ).filter(tag => tag !== undefined);
    setClickedTags(filteredTags);
  }
};

export const useGetMapPhotos = (clickedTags) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mapTags } = useSettings();
  const { setClickedTags } = usePhotographsContext();

  useEffect(() => {
    setTagsOnLoad(mapTags, setClickedTags, location)
  }, []);

  const { data, isFetched, isLoading, error, isFetching, refetch } = useQuery({
    queryKey: ["map-photos", clickedTags],
    queryFn: () => fetchMapPhotos(),
    keepPreviousData: true,
  });

  const fetchMapPhotos = async () => {
    const res = await getMapPhotos(clickedTags);
    updateUrlWithTags(clickedTags, navigate, location);
    return res;
  };

  return { data, isFetched, isLoading, error, refetch, isFetching };
};

export const useGetPhoto = (id) => {
  const { currentToken } = useAuth();

  const { data, isFetched, isLoading, error, refetch } = useQuery({
    queryKey: [`photo/${id}`, currentToken],
    queryFn: () => getPhotoById(id, currentToken),
    keepPreviousData: true,
  });

  return { data, isLoading, error, isFetched, refetch };
};

export function usePhotographs(clickedTags, latitude, longitude) {
  const { currentToken, tokenIsFetched } = useAuth();
  const { setTotalPhotos, filter, setClickedTags } = usePhotographsContext();
  const { mapTags } = useSettings();

  const isFirstRender = useRef(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setTagsOnLoad(mapTags, setClickedTags, location)
  }, []);

  const fetchPhotos = async ({ pageParam = 1, perPage = 20 }) => {
    const res = await getAllPhotos(
      pageParam,
      perPage,
      currentToken,
      clickedTags,
      latitude,
      longitude,
      filter
    );
    updateUrlWithTags(clickedTags, navigate, location);
    return res;
  };

  const {
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    refetch,
    ...result
  } = useInfiniteQuery({
    queryKey: ["/photos", currentToken, clickedTags, filter],
    refetchOnMount: isFirstRender.current,
    queryFn: ({ pageParam }) => {
      if (tokenIsFetched) {
        return fetchPhotos({ pageParam });
      }
    },
    getNextPageParam: (lastPage) => {
      if (lastPage?.last_page > lastPage?.current_page) {
        return lastPage?.current_page + 1;
      }
    },
    // enabled: !!latitude && !!longitude,
  });

  if (isFirstRender.current) {
    isFirstRender.current = false;
  }

  let photoData = [];

  if (result?.data && result?.isFetched) {
    setTotalPhotos(result?.data?.pages[0]?.total ?? 0);

    result?.data?.pages.map((page) =>
      page?.data?.map((location) => {
        return photoData.push(location);
      })
    );
  }

  return {
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    photoData,
    refetch,
    ...result,
  };
}

export const useGetPhotoMap = (id) => {
  const { data, isFetched, isLoading, error, refetch } = useQuery({
    queryKey: [`photo-map/${id}`],
    queryFn: () => getPhotoOnMapById(id),
    keepPreviousData: true,
  });

  return { data, isLoading, error, isFetched, refetch };
};

export const useGetFavoritesPhoto = (currentToken) => {
  const { data, isFetched, isLoading, error, refetch } = useQuery({
    queryKey: ["favorites", currentToken],
    queryFn: () =>
      currentToken ? getFavoritesPhotos(currentToken) : Promise.resolve([]),
    cacheTime: 0,
    enabled: !!currentToken,
  });

  useEffect(() => {
    if (currentToken) {
      refetch();
    }
  }, [currentToken, refetch]);

  return { data, isLoading, error, isFetched, refetch };
};
