import { useState } from 'react';
import { Link } from "react-router-dom";
import Place from '@mui/icons-material/Place';
import { usePlaceMap } from "../../hooks/usePlaces";
import { Close, ImageNotSupportedOutlined } from "@mui/icons-material";
import { usePhotographsContext } from "../../contexts/photographs-context";
import { Box, CardMedia, Typography, Snackbar, IconButton, Skeleton } from "@mui/material";

export default function PhotographyPlaceContainer({ id }) {

    const { data, isFetched, } = usePlaceMap(id);
    const { isPlacesDrawerOpened, setIsPlacesDrawerOpened, } = usePhotographsContext();

    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const style = {
        container: {
            maxWidth: 320,
            minWidth: { xs: 'auto', sm: 280 }
        },
        numberOfLines: {
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
        }
    };

    return (
        <Snackbar
            disableWindowBlurListener={true}
            open={isPlacesDrawerOpened}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            sx={{
                '.MuiSnackbarContent-message ': { padding: 0, margin: 0, },
                '.MuiPaper-root': {
                    position: 'relative',
                    margin: 0, backgroundColor: '#fff',
                    color: 'rgba(0, 0, 0, 0.87)', padding: '10px'
                }
            }}
            message={
                <>
                    {isFetched ?
                        <Box display={'flex'} sx={{ cursor: 'pointer' }} onClick={() => {
                            window.open(`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${data?.google_place_id}`, '_blank');
                        }}>
                            <Box sx={{ display: 'flex', paddingRight: 1, }}>
                                {data?.featured_image &&
                                    <CardMedia
                                        sx={{
                                            display: !isImageLoaded ? 'none' : 'initial', borderRadius: 1,
                                            width: { xs: 130, sm: 160 }, height: { xs: 90, sm: 110 },
                                        }}
                                        component="img"
                                        onLoad={(e) => { setIsImageLoaded(true); }}
                                        image={data?.featured_image}
                                        alt={data?.title}
                                    />
                                }
                                {((data?.featured_image && !isImageLoaded) || !data?.featured_image) &&
                                    <Box position={'relative'}>
                                        <Skeleton variant="rectangular" sx={{ borderRadius: 1, width: { xs: 130, sm: 160 }, height: { xs: 90, sm: 110 } }} />
                                        <ImageNotSupportedOutlined fontSize="large" color="disabled"
                                            sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                    </Box>
                                }
                            </Box>
                            <Box display={"flex"} justifyContent={'space-between'} flexDirection={'column'} height={'auto'} width={'100%'} sx={{ ...style.container, }}>
                                <Box px={1} pr={2} sx={{ ...style.container, }}>
                                    <IconButton
                                        size="small"
                                        aria-label="close"
                                        color="inherit"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsPlacesDrawerOpened(false);
                                        }}
                                        sx={{ p: '2px', position: 'absolute', top: 2, right: 2 }}
                                    >
                                        <Close fontSize="small" />
                                    </IconButton>
                                    <Typography variant="h4"
                                        sx={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "-webkit-box",
                                            WebkitLineClamp: "3",
                                            WebkitBoxOrient: "vertical",
                                            ...style.container, fontSize: { xs: '16px', md: '18px' }, pr: 1,
                                        }}>
                                        {data?.title}
                                    </Typography>
                                </Box>

                                <Box px={1} sx={{ marginTop: 'auto', marginLeft: 'auto' }}>
                                    {data?.google_place_id &&
                                        <Box display={"flex"} alignItems={'center'} color={'green'}>
                                            <Place fontSize='small' />
                                            <Link target="_blank" style={{ color: 'green' }} to={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${data?.google_place_id}`}>
                                                Open in Google Maps
                                            </Link>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        </Box> :
                        <Box display="flex">
                            <Skeleton variant="rectangular" sx={{ borderRadius: 1, mr: 1, width: { xs: 130, sm: 160 }, height: { xs: 90, sm: 110 } }} />
                            <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ minWidth: 0 }}>
                                <Box sx={{ ...style.container, minWidth: { xs: 180, sm: 296 }, maxWidth: 'unset', width: { xs: '100%', sm: '90%' }, mb: 1, mr: 2 }}>
                                    <Skeleton variant="text" height={24} />
                                </Box>
                                <IconButton onClick={() => { setIsPlacesDrawerOpened(false); }}
                                    size="small" sx={{ p: '2px', position: 'absolute', top: 2, right: 2 }}>
                                    <Close fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>}
                </>
            }
        />
    )
}