import { useSettings } from '../contexts/settings-context';
import { Typography, Container, Box } from '@mui/material';

const NoResultPage = ({ title, text, children }) => {
    const { isMobile } = useSettings();

    return (
        <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ textAlign: 'center', marginTop: 4 }}>
                <Typography variant={isMobile ? 'h6' : 'h4'} gutterBottom>
                    {title}
                </Typography>
                <Typography variant="caption" fontSize={isMobile ? '14px' : '16px'} sx={{ lineHeight: '1.4', color: 'dimgray' }}>
                    {text}
                </Typography>
                {children}
            </Box>
        </Container>
    );
};

export default NoResultPage;
