import { Box } from '@mui/material';
import Header from "../../components/header.components";
import { useSettings } from "../../contexts/settings-context";
import FooterComponent from '../../components/footer.component';
import BackButton from '../../components/ui/back-button.component';

const Contacts = () => {
    const { siteInfo, isMobile } = useSettings();

    function createMarkup() {
        return { __html: siteInfo?.support };
    }
    return (
        <Header>
            <Box display={'flex'} justifyContent={'center'} textAlign={'center'} sx={{ width: '90%', margin: '0 auto', minHeight: `calc(100vh - 64px)` }}>
                {isMobile ?
                    <BackButton sx={{ backgroundColor: '#F1F1F1', position: 'fixed', top: 10, left: 10, zIndex: 1000, }} />
                    : null}
                <Box pt={isMobile ? 0 : 10}>
                    <Box dangerouslySetInnerHTML={createMarkup()} />
                </Box>
            </Box>
            <FooterComponent />
        </Header>
    )
}

export default Contacts