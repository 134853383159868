import { useNavigate } from "react-router-dom";
import { useGetPhotoMap } from "../../hooks/usePhotos";
import { Close, ArrowForward } from "@mui/icons-material";
import { usePhotographsContext } from "../../contexts/photographs-context";
import { Box, CardMedia, Typography, Snackbar, IconButton, Button, Skeleton } from "@mui/material";

export default function MobilePhotographyContainer({ id }) {
    const navigate = useNavigate();
    const { data, isFetched, } = useGetPhotoMap(id);
    const { isPhotographsDrawerOpened, setIsPhotographsDrawerOpened } = usePhotographsContext();

    const detailsClickHandler = () => {
        setIsPhotographsDrawerOpened(false);
        navigate(`/photography/${id}`);
    }

    return (
        <Snackbar
            key={data?.name}
            disableWindowBlurListener={true}
            open={isPhotographsDrawerOpened}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            sx={{
                width: '100%',
                left: 0, right: 0, bottom: 0,
                '.MuiSnackbarContent-message ': { padding: 0, margin: 0, width: '100%' },
                '.MuiPaper-root': {
                    padding: 0, margin: 0, position: 'relative',
                    backgroundColor: '#fff', color: 'rgba(0, 0, 0, 0.87)', borderTopLeftRadius: 15, borderTopRightRadius: 15,
                }
            }}
            message={
                <>
                    {isFetched ?
                        <Box display={'flex'} flexDirection={'column'} onClick={() => detailsClickHandler()}>
                            <Box sx={{ display: 'flex', }}>
                                {data?.featured_image &&
                                    <CardMedia
                                        component="img"
                                        alt={data?.title}
                                        image={data?.featured_image}
                                        sx={{ width: "100%", height: 200, borderTopLeftRadius: 15, borderTopRightRadius: 15, }}
                                    />
                                }
                            </Box>
                            <Box display={"flex"} justifyContent={'space-between'} flexDirection={'column'} width={'100%'} pb={1}>
                                <Box padding={2} pb={'5px'}>
                                    <IconButton
                                        size="small"
                                        color="inherit"
                                        aria-label="close"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsPhotographsDrawerOpened(false)
                                        }}
                                        style={{ position: 'absolute', top: 10, right: 10, background: '#f0f0f0' }}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                    <Typography variant="h4" sx={{ fontSize: 18 }}>
                                        {data?.title}
                                    </Typography>
                                    <Typography variant="h4" sx={{ fontSize: 14, color: 'grey', mt: 1, mr: 5 }}>
                                        {data?.subtitle}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2 }}>
                                    <Button
                                        onClick={() => detailsClickHandler()}
                                        endIcon={<ArrowForward />} variant='contained' color="success"
                                        sx={{
                                            borderRadius: 15, height: 'fit-content', fontSize: 13,
                                            textTransform: 'unset', boxShadow: 'none'
                                        }}>
                                        Details
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        :
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection={'column'} pb={1}>
                            <Skeleton variant="rectangular" width={'100%'} height={200} sx={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }} />
                            <Skeleton variant="text" height={30} sx={{ mt: 1 }} width={'95%'} />
                            <Skeleton variant="text" height={30} width={'95%'} />
                            <Skeleton variant="text" height={0} width={'95%'} sx={{ mt: 3, mb: 2 }} />
                            <IconButton
                                size="small"
                                color="inherit"
                                aria-label="close"
                                onClick={() => setIsPhotographsDrawerOpened(false)}
                                style={{ position: 'absolute', top: 10, right: 10, background: '#f0f0f0' }}>
                                <Close fontSize="small" />
                            </IconButton>
                        </Box>}
                </>
            }
        />
    )
}