import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useGetPhotoMap } from '../../hooks/usePhotos';
import { useSettings } from '../../contexts/settings-context';
import { usePhotographsContext } from "../../contexts/photographs-context";
import { Close, ImageNotSupportedOutlined, ArrowForward } from "@mui/icons-material";
import { Box, CardMedia, Typography, Snackbar, IconButton, Skeleton, Button } from "@mui/material";

export default function DetailsPhotoContainer({ id, setClickedDetailsPlaceId, open }) {
    const navigate = useNavigate();
    const { isMobile } = useSettings();
    const { data, isFetched, } = useGetPhotoMap(id);
    const { setIsClickedPhotoDrawerOpened } = usePhotographsContext();

    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const style = {
        container: {
            maxWidth: 320,
            minWidth: { xs: 'auto', sm: 280 }
        },
        numberOfLines: {
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
        }
    };

    const detailsClickHandler = () => {
        setIsClickedPhotoDrawerOpened(false);
        setClickedDetailsPlaceId();
        navigate(`/photography/${id}`);
    }

    return (
        <Snackbar
            disableWindowBlurListener={true}
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            sx={{
                position: 'absolute', width: { md: '100%' },
                '.MuiSnackbarContent-message ': { padding: 0, margin: 0, },
                '.MuiPaper-root': {
                    position: 'relative',
                    margin: 0, backgroundColor: '#fff',
                    color: 'rgba(0, 0, 0, 0.87)', padding: '10px'
                }
            }}
            message={
                <>
                    {isFetched ?
                        <Box display={'flex'} onClick={() => detailsClickHandler()}>
                            <Box sx={{ display: 'flex', paddingRight: 1, }}>
                                {data?.featured_image &&
                                    <CardMedia
                                        sx={{
                                            display: !isImageLoaded ? 'none' : 'initial', borderRadius: 1,
                                            width: { xs: 130, sm: 160 }, height: { xs: 90, sm: 110 },
                                        }}
                                        component="img"
                                        onLoad={(e) => { setIsImageLoaded(true); }}
                                        image={data?.featured_image}
                                        alt={data?.title}
                                    />
                                }

                                {((data?.featured_image && !isImageLoaded) || !data?.featured_image) &&
                                    <Box position={'relative'}>
                                        <Skeleton variant="rectangular" sx={{ borderRadius: 1, width: { xs: 130, sm: 160 }, height: { xs: 90, sm: 110 } }} />
                                        <ImageNotSupportedOutlined fontSize="large" color="disabled"
                                            sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                    </Box>
                                }
                            </Box>
                            <Box display={"flex"} justifyContent={'space-between'} flexDirection={'column'} height={'auto'} width={'100%'} sx={{ ...style.container, }}>
                                <Box px={1} pr={2} sx={{ ...style.container, }}>
                                    <IconButton
                                        size="small"
                                        aria-label="close"
                                        color="inherit"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsClickedPhotoDrawerOpened(false);
                                            setClickedDetailsPlaceId();
                                        }}
                                        sx={{ p: '2px', position: 'absolute', top: 2, right: 2 }}
                                    >
                                        <Close fontSize="small" />
                                    </IconButton>
                                    <Typography variant="h4" sx={{
                                        ...style.container, fontSize: { xs: 18, lg: 19 },
                                        ...style.numberOfLines, WebkitLineClamp: 2,
                                    }}  >
                                        {data?.title}
                                    </Typography>

                                    <Typography variant="h4" sx={{
                                        ...style.container, fontSize: { xs: 14, lg: 15 }, color: 'grey',
                                        ...style.numberOfLines, WebkitLineClamp: 1,
                                    }}  >
                                        {data?.subtitle}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                                    <Button
                                        // onClick={() => detailsClickHandler()}
                                        endIcon={<ArrowForward />} variant='contained' color="success"
                                        sx={{
                                            borderRadius: 15, height: isMobile ? 30 : 'fit-content', fontSize: 13,
                                            textTransform: 'unset', boxShadow: 'none'
                                        }}>
                                        Details
                                    </Button>
                                </Box>
                            </Box>
                        </Box> :
                        <Box display="flex">
                            <Skeleton variant="rectangular" sx={{ borderRadius: 1, mr: 1, width: { xs: 130, sm: 160 }, height: { xs: 90, sm: 110 } }} />
                            <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ minWidth: 0 }}>
                                <Box sx={{ ...style.container, minWidth: 272, mb: 1, pr: 2 }}>
                                    <Skeleton variant="text" height={48} />
                                    <Skeleton variant="text" height={18} />
                                    <Skeleton variant="rectangular" width={100} height={30} sx={{
                                        position: 'absolute', bottom: 10, right: 10, borderRadius: 15
                                    }} />
                                </Box>
                                <IconButton onClick={() => { setIsClickedPhotoDrawerOpened(false); setClickedDetailsPlaceId(); }}
                                    size="small" sx={{ p: '2px', position: 'absolute', top: 2, right: 2 }}>
                                    <Close fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>}
                </>
            }
        />
    )
}