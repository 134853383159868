import { Alert, Snackbar } from '@mui/material';

const Notification = ({ isOpen, handleClick, success, info, statement, vertical, horizontal, duration, action }) => {
    return (
        <Snackbar
            open={isOpen}
            onClose={() => handleClick()}
            autoHideDuration={duration ? duration : 1300}
            anchorOrigin={{ vertical: `${vertical}`, horizontal: `${horizontal}` }}
        >
            {statement ? (
                <Alert variant="filled" severity="success">
                    {success}
                </Alert>
            ) : (
                info ? (
                    <Alert variant="filled" severity="error">
                        {info}
                        {action}
                    </Alert>
                ) : null
            )}
        </Snackbar>
    );
}

export default Notification;