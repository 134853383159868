import './what-is-obektiva.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header.components';
import { useDailyPhoto } from '../../hooks/useRandomImage';
import { useSettings } from '../../contexts/settings-context';
import { Button, Grid, Typography, Skeleton, Box } from '@mui/material';
import { FavoriteBorderOutlined, Public, CropOriginal } from '@mui/icons-material';
import FooterComponent from '../../components/footer.component';

const WhatIsObektiva = () => {

    const navigate = useNavigate();
    const { data } = useDailyPhoto();
    const { isMobile } = useSettings();
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    return (
        <Header>
            <Grid container justifyContent='center' alignContent='center' direction='column'>
                <Grid container>
                    <Grid container sx={{ position: 'relative' }}>
                        {data?.front_location?.featured_image &&
                            <img onLoad={(e) => { setIsImageLoaded(true); }} src={data?.front_location?.featured_image} alt={data?.front_location?.title} className='random-location-image' />
                        }

                        {((data?.front_location?.featured_image && !isImageLoaded) || !data?.front_location?.featured_image) &&
                            <Box sx={{ position: "relative" }}>
                                <Skeleton width='100vw' height="100vh" sx={{ transform: 'unset' }} animation={false} />
                            </Box>
                        }

                        {/* {data?.front_location?.featured_image ? <img onLoad={(e) => { setIsImageLoaded(true); }} src={data?.front_location?.featured_image} alt={data?.front_location?.title} className='random-location-image' /> :
                            <Skeleton width='100vw' height="90vh" sx={{ transform: 'unset' }} animation={false} />
                        } */}
                        <Grid item xs={12} className='random-location-image-details' onClick={() => navigate(`/photography/${data.front_location?.id}`)}>
                            <Typography className='random-location-image-title' color='#fff' variant='h3'>{data?.front_location?.title}</Typography>
                            {data?.front_location?.subtitle ? <Typography className='random-location-image-subtitle' color='#fff' variant='h5'>{data?.front_location?.subtitle}</Typography> : ''}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' sx={{ pt: 5, pb: 6 }}>
                    <Grid item md={3} xs={false} />
                    <Grid item sm={8} md={6} xs={11}>
                        <Typography id='go-to-text' className='info-text-home-page'>
                            Obektiva will give you an idea for the next big trip or short walk. Use the map to discover new places to visit.
                        </Typography>
                        <Typography id='go-to-text' className='info-text-home-page'>
                            If you like to take pictures, share the picture in Obektiva - for every place you have visited you can upload your pictures.
                        </Typography>
                        <Typography id='go-to-text' className='info-text-home-page'>
                            Add beauty to your home - order a print on canvas or photo paper of your favorite views.
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={false} />
                </Grid>
                <Grid container>
                    <Grid container className="icons-container">
                        <Grid item sm={2} xs={4} className="icons-text"><FavoriteBorderOutlined /><span>Discover</span></Grid>
                        <Grid item sm={2} xs={4} className="icons-text"><Public /><span>Travel</span> </Grid>
                        <Grid item sm={2} xs={4} className="icons-text"><CropOriginal /><span>Save the memory</span></Grid>
                    </Grid>
                    <Grid item sx={{ textAlign: 'center', p: 3 }} xs={12}>
                        <Button variant='outlined' sx={{
                            borderRadius: 5, color: 'black', borderColor: '#666666',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                borderColor: '#666666',
                            },
                            '&:active': {
                                backgroundColor: 'transparent',
                                borderColor: '#666666',
                            },
                            '&:focus': {
                                outline: 'none',
                                borderColor: '#666666',
                            }
                        }} component={Link} to="/map">Open the map</Button>
                    </Grid>
                </Grid>
                <Grid container sx={{ pb: 3, pt: 3 }}>
                    <Grid item sx={{ textAlign: 'center', pt: isMobile ? 1 : 4, p: 3 }} xs={12}>
                        <Typography variant='h5'>Where should I go?</Typography>
                    </Grid>
                    <Grid container className='container-suggested-images'>
                        {data?.suggested_photographies?.map(suggested => (
                            <Grid item md={2} sm={4} xs={6} key={suggested?.id} className="icons-text">
                                <Box onClick={() => {
                                    navigate(`/photography/${suggested?.id}`);
                                }} className="suggested-images" style={{ background: `url(${suggested.featured_image})` }} />
                                <span onClick={() => {
                                    navigate(`/photography/${suggested?.id}`);
                                }}>{suggested.title}</span>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item sx={{ textAlign: 'center', p: 1 }} xs={12}>
                        <Button variant='outlined' sx={{
                            borderRadius: 5, color: 'black', borderColor: '#666666',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                borderColor: '#666666',
                            },
                            '&:active': {
                                backgroundColor: 'transparent',
                                borderColor: '#666666',
                            },
                            '&:focus': {
                                outline: 'none',
                                borderColor: '#666666',
                            }
                        }} component={Link} to="/">See all</Button>
                    </Grid>
                </Grid>

                <FooterComponent />
            </Grid>
        </Header>
    );
};

export default WhatIsObektiva;
