import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { Box, Typography, TextField, Button } from '@mui/material';

const validationSchema = yup.object({
    email: yup.string().email("Enter a valid email!").required("Email is required!"),
})
const ForgottenPasswordForm = ({ onSubmit, isMobile }) => {
    return (
        <Formik
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            initialValues={{ email: '' }}
        >
            {({ isSubmitting, errors }) => (
                <Form style={{ width: isMobile ? '90%' : '70%', textAlign: 'center' }}>
                    <Field
                        id="email"
                        type="text"
                        name="email"
                        fullWidth
                        label="Email"
                        as={TextField}
                        variant="outlined"
                    />
                    {errors?.email ? <Box my={1}>
                        <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
                    </Box> : <Typography variant="caption">&nbsp;</Typography>}

                    <Box sx={{ mb: 3 }}>
                        <Button type="submit" variant="contained" color="success" fullWidth sx={{ borderRadius: 15, height: 50 }}>
                            Send an email
                        </Button>
                    </Box>

                    <Box>
                        <Typography variant='body2' fontSize={14}>
                            Don't have an account? &nbsp;
                            <Link to={'/register'} style={{ cursor: 'pointer', textUnderlineOffset: 5, color: 'green', textAlign: 'center' }}>
                                Sign Up
                            </Link>
                        </Typography>

                        <Typography variant='body2' fontSize={14} mt={2}>
                            Already have an account? &nbsp;
                            <Link to={'/login'} style={{ cursor: 'pointer', color: 'green', textUnderlineOffset: 5, textAlign: 'center' }}>
                                Sign In
                            </Link>
                        </Typography>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}

export default ForgottenPasswordForm