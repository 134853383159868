import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useRef, useMemo, useState } from 'react';
import { Icon } from 'leaflet';
import { MapContainer, TileLayer, ZoomControl, Tooltip, Marker, Popup } from "react-leaflet";
import { Link } from "react-router-dom";
import { Box, CircularProgress, Grid, IconButton, Badge, Typography } from "@mui/material";
import { GpsFixed, KeyboardDoubleArrowUp, Place } from '@mui/icons-material';
import PlacesList from './places-list.component';
import { useGetPlace } from '../../hooks/usePlaces';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useSettings } from '../../contexts/settings-context';
import DetailsPhotoContainer from '../map/details-photo-container.component';
import './places-details-map.styles.css';

export default function PhotographyPlacesDetailsMap({ photo }) {
    const mapRef = useRef(null);
    const [clickedPlaceId, setClickedPlaceId] = useState();
    const [showChosenPhoto, setShowChosenPhoto] = useState(true);
    const [clickedDetailsPhotographyId, setClickedDetailsPlaceId] = useState();
    const [isClickedPhotoDrawerOpened, setIsClickedPhotoDrawerOpened] = useState(false);

    const { data } = useGetPlace(clickedPlaceId);
    const { isMobile } = useSettings();

    let vh = window.innerHeight * 0.01;
    //* Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    const clickedPhotoMarkerIcon = new Icon({
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32],
        iconUrl: `${photo?.featured_image}`,
        className: "custom-marker-icon-clicked"
    });

    const onPhotoClickHandler = (id) => {
        setIsClickedPhotoDrawerOpened(false);
        setClickedDetailsPlaceId(id);
        setIsClickedPhotoDrawerOpened(true);
    };

    const placeClickHandler = (place) => {
        if (place?.id === clickedPlaceId) {
            setClickedPlaceId();
            setShowChosenPhoto(true);
            mapRef.current.closePopup();
        } else {
            setClickedPlaceId(place?.id);
            setShowChosenPhoto(false);
            let targetMarker = null;
            mapRef.current.eachLayer(function (layer) {
                if (layer instanceof L.Marker) {
                    if (layer.getLatLng().lat === place.latitude && layer.getLatLng().lng === place.longitude) {
                        targetMarker = layer;
                        return;
                    }
                }
            });
            targetMarker && targetMarker?.openPopup();
        }
    }

    function customBounds(locationElements) {
        if (locationElements && locationElements.length > 1) {
            // find the first element depending on the element type
            const firstElement = locationElements[0];
            let boundaries = {
                leftLongitude: firstElement?.longitude,
                rightLongitude: firstElement?.longitude,
                botLatitude: firstElement?.latitude,
                topLatitude: firstElement?.latitude
            };
            for (let index = 0; index < locationElements.length; index++) {
                // get current element depending on the element type
                const element = locationElements[index];
                boundaries.leftLongitude = Math.min(boundaries.leftLongitude, element.longitude);
                boundaries.rightLongitude = Math.max(boundaries.rightLongitude, element.longitude);
                boundaries.botLatitude = Math.min(boundaries.botLatitude, element.latitude);
                boundaries.topLatitude = Math.max(boundaries.topLatitude, element.latitude);
            }
            return [[boundaries.topLatitude, boundaries.leftLongitude], [boundaries.botLatitude, boundaries.rightLongitude]];
        }
    }

    let locationMarkersBounds = useMemo(() => photo?.places?.length > 1 ? customBounds(photo?.places) : null, [photo?.places?.length])
    function mapFlyToPlacesBounds(locationMarkersBounds) {
        if (mapRef.current && locationMarkersBounds) {
            mapRef?.current?.fitBounds(locationMarkersBounds, { paddingTopLeft: [0, isMobile ? 220 : 190] })
        }
    }

    useMemo(() => mapFlyToPlacesBounds(locationMarkersBounds), [locationMarkersBounds, mapRef.current])

    return (
        <Grid item xs={12}>
            {photo ? (
                <Box sx={{ position: 'relative' }}>
                    <Box position="absolute" top="0px" left="0px" zIndex={999} sx={{ width: '100%' }}>
                        <PlacesList places={photo?.places} onPhotographyClickedHandler={placeClickHandler} clickedPlaceId={clickedPlaceId} setClickedPlaceId={setClickedPlaceId} />
                    </Box>

                    <MapContainer
                        ref={mapRef}
                        dragging={true}
                        zoomControl={false}
                        worldCopyJump={true}
                        scrollWheelZoom={false}
                        attributionControl={false}
                        minZoom={3} maxZoom={18} zoom={8}
                        style={{ width: '100%', height: isMobile ? '80vh' : '95vh', borderRadius: !isMobile && "4px", }}
                        center={[photo?.latitude, photo?.longitude]}
                    >
                        <Box sx={{ position: 'absolute', bottom: isMobile ? '110px' : '80px', right: '10px', zIndex: 1000, }}>
                            <Badge sx={{
                                width: isMobile ? '44px' : 'initial', height: isMobile ? '44px' : 'initial',
                                background: 'white', borderRadius: 1, borderWidth: 1
                            }}>
                                <IconButton size='large' sx={{
                                    width: '100%', p: '5px', '&:hover': { backgroundColor: 'transparent', },
                                    '&.Mui-focusVisible': { backgroundColor: 'transparent' },
                                }} aria-label='center map button' onClick={() => mapFlyToPlacesBounds(locationMarkersBounds)}>
                                    <GpsFixed fontSize={isMobile ? 'medium' : 'small'} />
                                </IconButton>
                            </Badge>
                        </Box>

                        <ZoomControl position='bottomright' />

                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />

                        {(showChosenPhoto || data?.photographies?.length === 1) && (
                            <Marker riseOnHover={true} position={[photo?.latitude, photo?.longitude]} icon={clickedPhotoMarkerIcon}>
                                <Tooltip offset={[15, -15]}>{photo?.title}</Tooltip>
                            </Marker>
                        )}

                        {photo?.places?.map((place) =>
                            <Marker
                                key={place?.id}
                                eventHandlers={{
                                    click: () => {
                                        placeClickHandler(place);
                                    },
                                }} riseOnHover={true} position={[place?.latitude, place?.longitude]}
                                icon={L.divIcon({
                                    iconSize: [22, 22],
                                    html: `${clickedPlaceId === place?.id && clickedPlaceId ? `<div class='custom-pin-places clicked-marker-blue'></div>`
                                        : `<div class='custom-pin-places'></div>`}`,
                                    className: clickedPlaceId === place?.id && clickedPlaceId && 'custom-div-icon'
                                })}
                            >
                                {(place && place.id !== clickedPlaceId) && <Tooltip offset={[10, 0]}>{place.title}</Tooltip>}
                                <Popup offset={[0, -5]}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: '180px' }}>
                                        <Typography variant="h5" sx={{ color: 'gray', fontSize: 14, fontWeight: 400 }}>{place?.title}</Typography>
                                        <Box display={"flex"} alignItems={'center'} color={'green'} pt={1} ml={'-4px'}>
                                            <Place fontSize='small' style={{ paddingRight: 2 }} />
                                            <Link target="_blank" style={{ color: 'green', textAlign: 'center' }} to={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${place?.google_place_id}`}>
                                                Open in Google Maps
                                            </Link>
                                        </Box>
                                    </Box>
                                </Popup>
                            </Marker>
                        )}

                        <MarkerClusterGroup
                            chunkedLoading
                            key={Math.random().toString(36).slice(2)}
                            removeOutsideVisibleBounds={true} maxClusterRadius={28} showCoverageOnHover={false}
                            spiderfyOnMaxZoom={true} zoomToBoundsOnClick={true} animate={true}
                            iconCreateFunction={(cluster) => L.divIcon({
                                className: 'photographies-details', iconSize: L.point(36, 36, true), iconAnchor: [16, 32],
                                html: '<span>' + cluster.getChildCount() + '</span>' +
                                    `<img className='cluster-icon-details' src='${cluster.getAllChildMarkers()[0].options.icon.options.iconUrl}' alt='${cluster.getChildCount()} photographies in a cluster'/>`
                            })}
                        >
                            {data?.photographies?.map((item) => (
                                item?.id !== photo?.id &&
                                <Marker
                                    key={item?.id}
                                    position={[item?.latitude, item?.longitude]}
                                    riseOnHover={true}
                                    icon={new Icon({
                                        iconUrl: `${item?.featured_image}`,
                                        iconSize: [32, 32],
                                        iconAnchor: [16, 32],
                                        popupAnchor: [0, -32],
                                        className: clickedDetailsPhotographyId === item?.id ? "custom-marker-icon-clicked" : "custom-marker-icon"
                                    })}
                                    eventHandlers={{
                                        click: () => {
                                            onPhotoClickHandler(item?.id);
                                        },
                                    }}
                                >
                                    <Tooltip offset={[15, -15]}>{item?.title}</Tooltip>
                                </Marker>
                            ))}
                        </MarkerClusterGroup>

                        {(isClickedPhotoDrawerOpened && clickedDetailsPhotographyId) && <DetailsPhotoContainer id={clickedDetailsPhotographyId} setClickedDetailsPlaceId={setClickedDetailsPlaceId} open={isClickedPhotoDrawerOpened} />}
                    </MapContainer>
                </Box>
            ) : (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress size={50} />
                </Box>
            )
            }
        </Grid >
    );
}
