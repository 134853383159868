import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Share, Delete } from "@mui/icons-material";
import { useAuth } from "../../../contexts/auth-context";
import CustomIconButton from "../../ui/custom-button.component";
import { useSettings } from '../../../contexts/settings-context';
import { Box, Typography, Grid, Card, CardContent, Tooltip, Dialog, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";

const FavoritePhotographyCard = ({ photo, refetch, openShareModal }) => {
    const navigate = useNavigate();
    const { isMobile } = useSettings();
    const { favoriteHandler } = useAuth();

    const shareIconButtonRef = useRef();
    const deleteIconButtonRef = useRef();
    const [isDeletedOpen, setIsDeletedOpen] = useState(false);

    const calculateTimeDifference = () => {
        const favourDate = new Date(photo?.favour_date);
        const currentDate = new Date();
        const timeDifference = currentDate.getTime() - favourDate.getTime();
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

        if (daysDifference >= 365) {
            const years = Math.floor(daysDifference / 365);
            return `${years} year${years > 1 ? 's' : ''} ago`;
        } else if (daysDifference >= 30) {
            const months = Math.floor(daysDifference / 30);
            return `${months} month${months > 1 ? 's' : ''}`;
        } else if (daysDifference >= 1) {
            return `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
        } else {
            const hours = Math.floor(timeDifference / (1000 * 3600));
            const minutes = Math.floor((timeDifference % (1000 * 3600)) / (1000 * 60));
            if (hours > 0) {
                return `${hours}:${minutes < 10 ? '0' : ''}${minutes}h ago`;
            } else {
                return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
            }
        }
    };

    return (
        <Grid item xl={3} md={4} sm={6} xs={12}>
            <Card sx={{ boxShadow: "unset", position: "relative" }}>
                <Box
                    alt={photo?.title}
                    sx={{
                        position: "relative",
                        height: "230px",
                        borderRadius: 5,
                        backgroundImage: `url(${photo?.featured_image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        cursor: "pointer",
                    }}
                >
                    <Tooltip title="Share" arrow ref={shareIconButtonRef?.current}>
                        <CustomIconButton icon={<Share />} onClick={() => openShareModal(photo)} buttonStyle={{
                            position: "absolute",
                            bottom: isMobile ? 10 : 5,
                            right: 60,
                            color: 'white', backgroundColor: 'rgba(0,0,0,0.6)'
                        }} />
                    </Tooltip>

                    <Tooltip title="Delete" arrow ref={deleteIconButtonRef?.current}>
                        <CustomIconButton icon={<Delete />} onClick={() => setIsDeletedOpen(true)} buttonStyle={{
                            position: "absolute",
                            bottom: isMobile ? 10 : 5,
                            right: 10,
                            color: 'white', backgroundColor: 'rgba(0,0,0,0.6)'
                        }} />
                    </Tooltip>
                </Box>

                <CardContent sx={{ p: 0, "&:last-child": { pb: 2 }, pl: isMobile && "5px" }} onClick={() => navigate(`/photography/${photo?.id}`)}>
                    <Typography sx={{ textDecoration: "none", color: "unset", fontSize: "16px", my: "5px", cursor: "pointer" }}>
                        {photo?.title}
                    </Typography>
                    <Typography variant="caption" sx={{ lineHeight: '1.4', color: 'dimgray' }}>
                        {calculateTimeDifference()}
                    </Typography>
                </CardContent>
            </Card>

            <Dialog open={isDeletedOpen} onClose={() => setIsDeletedOpen(false)}   >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Are you sure you want to delete - "{photo?.title}" from your favorites?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" sx={{ color: 'grey', borderRadius: 5 }} onClick={() => setIsDeletedOpen(false)}>Cancel</Button>
                    <Button variant="text" color="success" sx={{ borderRadius: 5 }} onClick={() => { setIsDeletedOpen(false); favoriteHandler(photo?.id, refetch) }} autoFocus>Continue</Button>
                </DialogActions>
            </Dialog>
        </Grid >
    );
};

export default FavoritePhotographyCard;
