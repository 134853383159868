
import { useAuth } from '../../../contexts/auth-context';
import { Dialog, Box, DialogTitle, DialogContent, Typography, Button } from '@mui/material';
import { FacebookIcon, FacebookShareButton, XIcon, TwitterShareButton, ViberIcon, ViberShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";

const SharePhotographyAtSocialMedias = ({ photo, isShareOpen, shareClose }) => {
    const { setSuccessMessage } = useAuth();

    const photoUrl = process.env.REACT_APP_ENV === "staging" ? `https://staging.obektiva.com/photography/${photo?.id}` : `https://obektiva.com/photography/${photo?.id}`;
    // const photoUrl = `https://staging.obektiva.com/photography/${photo?.id}`

    return (
        <Dialog
            open={isShareOpen}
            onClose={shareClose}
        >
            <Box>
                <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>
                    Share with:
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '16px' }}>
                        <FacebookShareButton
                            url={photoUrl}
                            hashtag={`#photography`}
                            style={{ marginRight: '10px' }}
                        >
                            <FacebookIcon round size={32} />
                        </FacebookShareButton>

                        <TwitterShareButton url={photoUrl}>
                            <XIcon size={32} round={true} style={{ marginRight: '10px' }} />
                        </TwitterShareButton>

                        <ViberShareButton url={photoUrl}>
                            <ViberIcon size={32} round={true} style={{ marginRight: '10px' }} />
                        </ViberShareButton>

                        <WhatsappShareButton url={photoUrl}>
                            <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                    </Box>

                    <Typography className="link" id="url" variant="body2" sx={{ backgroundColor: 'rgba(0,0,0,.055)', p: 1, wordWrap: 'break-word', }}>
                        {photoUrl}
                    </Typography>

                    <Box display={'flex'} justifyContent={'center'} my={3}>
                        <Button sx={{ borderRadius: 5 }} onClick={() => shareClose()}>
                            Close
                        </Button>

                        <Button variant='outlined' sx={{ borderRadius: 5, mx: 1 }} onClick={() => {
                            navigator.clipboard.writeText(photoUrl);
                            setSuccessMessage('Link copied to clipboard');
                        }}>
                            Copy Link
                        </Button>
                    </Box>
                </DialogContent>
            </Box>
        </Dialog >
    );
}

export default SharePhotographyAtSocialMedias;
