import { useState } from 'react';
import NoResultPage from '../NoResultPage';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/auth-context';
import Header from "../../components/header.components";
import { useGetFavoritesPhoto } from '../../hooks/usePhotos';
import { useSettings } from '../../contexts/settings-context';
import BackButton from '../../components/ui/back-button.component';
import { Box, Grid, Typography, Divider, Button, } from '@mui/material';
import FavoritePhotographyCard from '../../components/auth/user/favorite-photography-card';
import SharePhotographyAtSocialMedias from '../../components/auth/user/share-photography-socials-media.component';

const Favorites = () => {
    const navigate = useNavigate();
    const { isMobile } = useSettings();
    const { currentToken } = useAuth();
    const { data, refetch } = useGetFavoritesPhoto(currentToken, true);

    const [clickedPhoto, setClickedPhoto] = useState();
    const [isShareOpen, setIsShareOpen] = useState(false);

    const openShareModal = (inputPhot) => {
        setClickedPhoto(inputPhot);
        setIsShareOpen(true);
    }

    return (
        <Header>
            <Box sx={{ background: "white", pb: 10, pt: isMobile ? 0 : 8, position: 'relative' }}>
                <Grid container sx={{ pt: isMobile ? 1 : 3 }}>
                    <Grid item md={1} xs={false} />
                    <Grid item md={10} xs={12}>
                        {isMobile ?
                            <Box display={'flex'} alignItems={'center'} mt={2}>
                                <BackButton sx={{
                                    backgroundColor: '#F1F1F1',
                                    position: 'fixed',
                                    top: 20,
                                    left: 10,
                                    zIndex: 1000,
                                }} />
                                <Box display={'flex'} justifyContent={'center'} sx={{ width: '100%' }}>
                                    <Typography sx={{ color: 'black', textAlign: 'center' }} variant='h5'>Favorites</Typography>
                                </Box>
                            </Box> : <>
                                <Grid container justifyContent="center" sx={{ flexWrap: 'nowrap', }}>
                                    <Grid item md={12} xs={11}>
                                        <Typography id="back-to-top-anchor" className='breadcrumb-menu-center' variant='h4' gutterBottom>
                                            Favorites
                                        </Typography>
                                        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Link style={{ cursor: 'pointer', color: 'gray', textDecoration: 'none' }} to={"/"}>
                                                Home
                                            </Link>
                                            <Typography color="text.primary"> &nbsp; / &nbsp; Favorites</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ mt: 1, mb: 4, }} />
                            </>}

                        <Grid container justifyContent="center" sx={{ flexWrap: 'nowrap', mt: 3 }}>
                            <Grid item md={12} xs={11}>
                                <Grid container direction="row" spacing={isMobile ? 0 : 3} flexWrap={'wrap'} alignItems="stretch">
                                    {data?.length !== 0 ? data?.map((photo) => (
                                        <FavoritePhotographyCard key={photo.id} photo={photo} refetch={refetch} openShareModal={openShareModal} />
                                    )) :
                                        <Grid item xs={12}>
                                            <NoResultPage title={"No favorite photographies found"} text={"You haven't marked any photographies as favorites yet."}>
                                                <Box>
                                                    <Button onClick={() => navigate('/')} variant="contained" color="success" sx={{ borderRadius: 10, mt: 2, height: 50 }}>
                                                        Check them out
                                                    </Button>
                                                </Box>
                                            </NoResultPage>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1} xs={false} />
                </Grid>
            </Box>
            <SharePhotographyAtSocialMedias photo={clickedPhoto} shareClose={() => setIsShareOpen(false)} isShareOpen={isShareOpen} />
        </Header >
    )
}

export default Favorites