
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';

const BackButton = ({ ...props }) => {
    const navigate = useNavigate();

    const onClick = () => {
        const isExistPreviousPage = window.history.length > 2;

        if (isExistPreviousPage) {
            navigate(-1);
        } else {
            navigate('/');
        }
    }

    return (
        <IconButton  {...props} disableRipple onClick={onClick}>
            <ArrowBack />
        </IconButton>
    )
}

export default BackButton 