import { useEffect, useState } from 'react';
import Place from '@mui/icons-material/Place';
import Hiking from '@mui/icons-material/Hiking';
import { useGetPhoto } from '../hooks/usePhotos';
import { useParams, Link } from 'react-router-dom';
import PublicIcon from '@mui/icons-material/Public';
import { useSettings } from '../contexts/settings-context';
import FooterComponent from '../components/footer.component';
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import { Typography, Box, Grid, Skeleton, Paper } from '@mui/material';
import PhotographyCarousel from '../components/photography-details/photography-carousel.component';
import SharePhotographyAtSocialMedias from '../components/auth/user/share-photography-socials-media.component';
import PhotographyPlacesDetailsMap from '../components/photography-details/photography-places-details-map.component';

export default function PhotographyDetails() {
    const { id } = useParams();
    const { isMobile } = useSettings();
    const { isFetched, data: photo, refetch } = useGetPhoto(id);

    const [color, setColor] = useState('');
    const [clickedPhoto, setClickedPhoto] = useState();
    const [isShareOpen, setIsShareOpen] = useState(false);

    function createMarkup() {
        return { __html: photo?.description };
    }

    useEffect(() => {
        if (photo?.accessibility < 4) {
            setColor('#43a047');
        } else if (photo?.accessibility < 7) {
            setColor('#ffeb3b');
        } else {
            setColor('#dd2c00');
        }
    }, [photo]);

    const openShareModal = (inputPhot) => {
        setClickedPhoto(inputPhot);
        setIsShareOpen(true);
    }

    return (
        <>
            {isFetched ?
                <>
                    <PhotographyCarousel photo={photo} refetch={refetch} openShareModal={openShareModal} />
                    <Grid container justifyContent="center" style={{ backgroundColor: '#fff' }}>
                        <Grid item xs={1} lg={2} />
                        <Grid item xs={10} lg={8} sx={{ pb: 5 }}>
                            <Grid item xs={12} my={isMobile ? 2 : 5}>
                                <Typography fontSize={'1.8em'} mt={1}>
                                    {photo?.title}
                                </Typography>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={6} lg={8} sx={{ borderRight: !isMobile && ' 2px solid #eee', pr: !isMobile && 3 }}>
                                    <Typography variant='body2'>
                                        <Box sx={{ "h2:first-of-type": { mt: 0 } }} dangerouslySetInnerHTML={createMarkup()}>
                                        </Box>
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} md={6} lg={4} sx={{ pl: !isMobile && 3, }}>
                                    <Grid item xs={12}>
                                        <Grid item display={'flex'} flexDirection={'row'}>
                                            <Grid item display={'flex'} flexDirection={'column'}>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <PublicIcon fontSize='medium' />
                                                    <Typography variant='h6'>
                                                        &nbsp;  Geographical coordinates
                                                    </Typography>
                                                </Box>
                                                <Box display={'flex'} flexDirection={'column'}>
                                                    <Typography variant='body' sx={{ color: '#868686', m: 1 }}>
                                                        &#966; &nbsp; {photo?.latitude}
                                                    </Typography>

                                                    <Typography variant='body' sx={{ color: '#868686', mb: 1, ml: 1 }}>
                                                        &#955; &nbsp; {photo?.longitude}
                                                    </Typography>

                                                    <Link style={{ display: 'flex', alignItems: 'center', color: 'green' }} variant="outlined" to={`https://www.google.com/maps/dir/?api=1&destination=${photo.latitude}%2C${photo.longitude}&travelmode=driving`} target="_blank" rel='noreferrer' >
                                                        <Place fontSize='small' />&nbsp;Open in Google Maps
                                                    </Link>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <Grid item display={'flex'} flexDirection={'row'} mt={4}>
                                            <Grid item display={'flex'} flexDirection={'column'}>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <Hiking fontSize='medium' />
                                                    <Typography variant='h6'>
                                                        &nbsp; Accessibility / difficulty
                                                    </Typography>
                                                </Box>

                                                <Box display={'flex'} alignItems={'center'}>
                                                    <FiberManualRecord style={{ color: `${color}` }} fontSize='small' />
                                                    <Typography variant='body' sx={{ color: '#868686', m: 1 }}>
                                                        &nbsp;{photo?.accessibility}/10
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <SharePhotographyAtSocialMedias photo={clickedPhoto} shareClose={() => setIsShareOpen(false)} isShareOpen={isShareOpen} />
                        </Grid>
                        <Grid item xs={1} lg={2} />
                        {photo?.places?.length !== 0 ? <>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <PhotographyPlacesDetailsMap photo={photo} />
                            </Grid>
                        </> : null
                        }
                    </Grid>
                    <Box>
                        <FooterComponent />
                    </Box>
                </> :
                <Box>
                    <Skeleton variant="rectangular" height={isMobile ? '0' : '75vh'} />
                </Box >
            }
        </>
    )
}
