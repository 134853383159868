import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth-context';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { updateUser } from '../../../services/user.services';
import { Paper, Typography, TextField, Button, Box, Grid } from '@mui/material';

const validationSchema = yup.object({
    old_password: yup
        .string()
        .required('Old Password is required'),
    password: yup
        .string()
        .min(6, 'Password should be at least 6 characters long!')
        .required('Password is required'),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match!')
        .required('Confirm Password is required'),
});

const ChangePasswordForm = () => {
    const { currentToken, setIsLoaded, setErrorMessage, changeCurrentUser } = useAuth();

    async function onSubmitHandler(values) {
        setIsLoaded(true);
        setErrorMessage('');
        const result = await updateUser(values, currentToken);

        if (result?.status === 200) {
            await changeCurrentUser(currentToken);
        } else {
            setErrorMessage(result);
        }
        setIsLoaded(false);
    }

    return (
        <Paper sx={{ borderRadius: 10, padding: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='body1' align='left'>
                    Change password
                </Typography>

                <Typography variant='body1' component={Link} to='/' sx={{ textDecoration: 'none', color: 'gray' }}>
                    Cancel
                </Typography>
            </Box>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={6} sm={6} md={6}>
                    <Formik
                        enableReinitialize
                        onSubmit={onSubmitHandler}
                        validationSchema={validationSchema}
                        initialValues={{ old_password: '', password: '', password_confirmation: '' }}
                    >
                        <Form>
                            <Grid item xs={12} md={12} lg={12}>
                                <Field
                                    as={TextField}
                                    type="password"
                                    label="Old Password"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    id="old_password"
                                    name="old_password"
                                />
                                <ErrorMessage name="old_password" component="div" style={{ color: 'red' }} />
                            </Grid>

                            <Grid item xs={12} md={12} lg={12}>
                                <Field
                                    as={TextField}
                                    type="password"
                                    label="New password"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    id="password"
                                    name="password"
                                />
                                <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
                            </Grid>

                            <Grid item xs={12} md={12} lg={12}>
                                <Field
                                    as={TextField}
                                    type="password"
                                    label="Confirm Password"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="password_confirmation"
                                    name="password_confirmation"
                                />
                                <ErrorMessage name="password_confirmation" component="div" style={{ color: 'red' }} />
                            </Grid>

                            <Button type="submit" variant="contained" color="success" fullWidth sx={{ borderRadius: 15, mt: 2 }}>
                                Save changes
                            </Button>
                        </Form>
                    </Formik>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ChangePasswordForm;
