import * as yup from 'yup';
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { TextField, Button, Box, Grid, Typography } from '@mui/material';

const schema = yup.object({
    email: yup.string().email('Invalid email address').required('Email is required'),
    password: yup.string().required('Password is required'),
});

const MobileLoginForm = ({ onSubmit }) => {
    return (
        <Formik
            onSubmit={onSubmit}
            validationSchema={schema}
            initialValues={{
                email: '',
                password: '',
            }}
        >
            <Form style={{ width: '100%', }}>
                <Grid item xs={12} md={12} lg={12}>
                    <Field
                        fullWidth
                        id="email"
                        name="email"
                        type="email"
                        as={TextField}
                        margin="normal"
                        variant="outlined"
                        label="Email"
                        InputLabelProps={{ shrink: true }}
                    />
                    <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Field
                        as={TextField}
                        type="password"
                        label="Password"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="password"
                        name="password"
                        InputLabelProps={{ shrink: true }}
                    />
                    <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
                </Grid>

                <Button type="submit" variant="contained" color="success" fullWidth sx={{ borderRadius: 15, mt: 2, height: 50 }}>
                    Sign In
                </Button>

                <Box mt={2} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <Typography variant="body2" textAlign={'center'}>
                        Don't have an account? &nbsp;
                        <Link to={'/register'} style={{ color: 'green', textDecoration: 'underline', }}> Sign up!</Link>
                    </Typography>

                    <Link to={'/forgotten-password'} style={{ color: 'black', textAlign: 'center', marginTop: 7, }}>
                        Forgotten password?
                    </Link>
                </Box>
            </Form>
        </Formik>
    );
};

export default MobileLoginForm;