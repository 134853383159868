import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './map.styles.css';
import Header from '../header.components';
import Controls from './map-controls.component';
import { Box, CircularProgress, Tooltip, Checkbox, Typography, Switch, ButtonGroup, IconButton } from "@mui/material";
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useGetMapPlaces } from '../../hooks/usePlaces';
import { useGetMapPhotos } from '../../hooks/usePhotos';
import { useRef, useState, useMemo, memo } from 'react';
import { useSettings } from '../../contexts/settings-context';
import PlaceMarkerComponent from './markers/place-marker.component';
import PhotographyContainer from "./photography-container.componen";
import { MapContainer, Marker, TileLayer, ZoomControl } from "react-leaflet";
import PhotographsFilter from '../home/photographs-filter.component';
import { usePhotographsContext } from '../../contexts/photographs-context';
import PhotographyPlaceContainer from './photography-place-container.component';
import MobilePhotographyContainer from './mobile-photography-container.component';
import CustomPhotographyMarker from './markers/custom.photography.marker.component';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import WrongLocationOutlinedIcon from '@mui/icons-material/WrongLocationOutlined';
import { useAuth } from '../../contexts/auth-context';

const defaultIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

export default function MapComponent() {
    const mapRef = useRef(null);

    const { isMobile, userLocation, setUserLocation } = useSettings();
    const { mapHeight, isPhotographsDrawerOpened, map, setMap, clickedPhotographyId, setClickedPhotographyId, setIsPhotographsDrawerOpened, clickedTags,
        clickedPlaceId, setClickedPlaceId, isPlacesDrawerOpened, setIsPlacesDrawerOpened, zoomLevel, setZoomLevel, showPlacesOnMap,
        setShowPlacesOnMap, currentMapCenter, setCurrentMapCenter } = usePhotographsContext();
    const { setErrorMessage } = useAuth();

    const [loadingUserLocation, setLoadingUserLocation] = useState(false);

    const { data: placesData, isFetching: placesFetching } = useGetMapPlaces();
    const { data, isFetched, isFetching, } = useGetMapPhotos(clickedTags);

    let vh = window.innerHeight * 0.01;
    //* Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    function customBounds(locationElements) {
        if (locationElements && locationElements.length > 1) {
            // find the first element depending on the element type
            const firstElement = locationElements[0];
            let boundaries = {
                leftLongitude: firstElement?.longitude,
                rightLongitude: firstElement?.longitude,
                botLatitude: firstElement?.latitude,
                topLatitude: firstElement?.latitude
            };
            for (let index = 0; index < locationElements.length; index++) {
                // get current element depending on the element type
                const element = locationElements[index];
                boundaries.leftLongitude = Math.min(boundaries.leftLongitude, element.longitude);
                boundaries.rightLongitude = Math.max(boundaries.rightLongitude, element.longitude);
                boundaries.botLatitude = Math.min(boundaries.botLatitude, element.latitude);
                boundaries.topLatitude = Math.max(boundaries.topLatitude, element.latitude);
            }
            return [[boundaries.topLatitude, boundaries.leftLongitude], [boundaries.botLatitude, boundaries.rightLongitude]];
        }
    }

    let locationMarkersBounds = useMemo(() => data?.length > 1 ? customBounds(data) : null, [data?.length])

    function mapFlyToPhotographiesBounds(locationMarkersBounds) {
        if (mapRef?.current && locationMarkersBounds && !isMobile) {
            mapRef.current.fitBounds(locationMarkersBounds)
        }
    }
    useMemo(() => mapFlyToPhotographiesBounds(locationMarkersBounds), [locationMarkersBounds])

    const onPhotographyClickedHandler = async (id) => {
        setIsPlacesDrawerOpened(false);
        setClickedPhotographyId(id);
        setIsPhotographsDrawerOpened(true);
    };

    const onPlaceClickedHandler = async (id) => {
        setIsPhotographsDrawerOpened(false);
        setClickedPlaceId(id);
        setIsPlacesDrawerOpened(true);
    };

    mapRef?.current?.on('moveend', function (e) {
        let center = mapRef?.current?.getCenter();
        if (center?.lat && center?.lng) {
            setCurrentMapCenter({ latitude: center.lat, longitude: center.lng });
        } else {
            setCurrentMapCenter({ latitude: null, longitude: null })
        }
    });

    mapRef?.current?.on('zoomend', function (ev) {
        setZoomLevel(mapRef?.current?.getZoom());
    });

    mapRef?.current?.on('zoomstart', function (ev) {
        setZoomLevel(mapRef?.current?.getZoom());
    });

    async function centerMapHandler() {
        // !TODO: loading state and disable for gps button
        setLoadingUserLocation(true);

        if (navigator.geolocation && (!sessionStorage.getItem('currentLat') && !sessionStorage.getItem('currentLong'))) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    setUserLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude });
                    sessionStorage.setItem('currentLat', position.coords.latitude)
                    sessionStorage.setItem('currentLong', position.coords.longitude)
                    mapRef.current.flyTo([position.coords.latitude, position.coords.longitude], 10);
                    setLoadingUserLocation(false);
                },
                (error) => {
                    setErrorMessage('Your location is not available. Please enable location services in your browser settings.')
                    setLoadingUserLocation(false)
                },
                { enableHighAccuracy: true }
            );
        } else {
            mapRef.current.flyTo([userLocation?.latitude, userLocation?.longitude], 10);
            setLoadingUserLocation(false);
        }
    }

    return (
        <Header>
            {(isFetching || loadingUserLocation || placesFetching) && (
                <Box display="flex" justifyContent="center" alignItems="center" position="absolute" zIndex={999} width="100%" height="100%">
                    <CircularProgress size={50} />
                </Box>
            )}

            <Box>
                <MapContainer id={'map'}
                    ref={mapRef}
                    minZoom={3} maxZoom={18} zoom={zoomLevel}
                    zoomControl={false}
                    dragging={true}
                    attributionControl={false}
                    worldCopyJump={true}
                    scrollWheelZoom={false}
                    center={[currentMapCenter?.latitude ?? userLocation?.latitude ?? 42.7662665, currentMapCenter?.longitude ?? userLocation?.longitude ?? 25.2385780]}
                    whenReady={(e) => e && setMap(e.target)}
                    style={{ height: mapHeight, position: 'relative' }}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    <ZoomControl position='bottomright' />

                    {(showPlacesOnMap && placesData) ? placesData?.map((place) => <PlaceMarkerComponent key={place?.id} place={place} clickedPlaceId={clickedPlaceId} onPlaceClickedHandler={onPlaceClickedHandler} />) : null}

                    {/* {isMobile ?
                        <MarkerClusterGroup
                            chunkedLoading
                            key={Math.random().toString(36).slice(2)}
                            removeOutsideVisibleBounds={true} maxClusterRadius={28} showCoverageOnHover={false}
                            spiderfyOnMaxZoom={true} zoomToBoundsOnClick={true} animate={true}
                            iconCreateFunction={(cluster) => L.divIcon({
                                iconSize: L.point(22, 22, true),
                                className: 'custom-div-icon',
                                html: '<span>' + cluster.getChildCount() + '</span>' +
                                    `<div class=${(isPhotographsDrawerOpened && clickedPhotographyId) ? 'custom-pin-disabled' : 'custom-pin'}></div>`
                            })}
                        >
                            {data?.map(photo =>
                                <CustomPhotographyMarker photo={photo} key={photo?.id} onPhotographyClickedHandler={onPhotographyClickedHandler} />
                            )}
                        </MarkerClusterGroup>
                        : */}
                    <MarkerClusterGroup
                        chunkedLoading
                        key={Math.random().toString(36).slice(2)}
                        removeOutsideVisibleBounds={true} maxClusterRadius={28} showCoverageOnHover={false}
                        spiderfyOnMaxZoom={true} zoomToBoundsOnClick={true} animate={true}
                        iconCreateFunction={(cluster) => L.divIcon({
                            className: 'photographies', iconSize: L.point(46, 46, true),
                            html: '<span>' + cluster.getChildCount() + '</span>' +
                                `<img className='cluster-icon' src='${cluster.getAllChildMarkers()[0].options.icon.options.iconUrl}' alt='${cluster.getChildCount()} photographies in a cluster'/>`
                        })}
                    >
                        {data?.map(photo =>
                            <CustomPhotographyMarker photo={photo} key={photo?.id} onPhotographyClickedHandler={onPhotographyClickedHandler} />
                        )}
                    </MarkerClusterGroup>
                    {/* } */}

                    {(userLocation?.latitude && userLocation?.longitude) &&
                        <Marker
                            icon={defaultIcon}
                            position={[userLocation?.latitude, userLocation?.longitude]}
                        />
                    }

                    <Controls isMobile={isMobile} centerMapHandler={centerMapHandler} />

                    {isMobile ?
                        <Box sx={{
                            padding: '10px',
                            position: 'absolute',
                            borderRadius: 2,
                            backgroundColor: showPlacesOnMap ? '#575757' : 'white',
                            bottom: '165px', right: '10px', zIndex: 1000, '&:hover': {},
                        }} onClick={() => { setShowPlacesOnMap(!showPlacesOnMap) }}>
                            <IconButton
                                size="large"
                                sx={{
                                    background: showPlacesOnMap && '#575757',
                                    p: '0px', '&:hover': { backgroundColor: showPlacesOnMap ? '#575757' : 'white', },
                                }}>
                                {showPlacesOnMap ? <WrongLocationOutlinedIcon sx={{ color: 'white' }} /> : <AddLocationAltOutlinedIcon fontSize='medium' sx={{ color: 'gray' }} />}
                            </IconButton>
                        </Box> :
                        <Box sx={{
                            padding: '5px',
                            position: 'absolute',
                            backgroundColor: showPlacesOnMap ? '#575757' : 'white',
                            bottom: '160px', right: '10px', zIndex: 1000, borderRadius: 1, '&:hover': {},
                        }} onClick={() => { setShowPlacesOnMap(!showPlacesOnMap) }}>
                            <Tooltip title={showPlacesOnMap ? 'Hide places' : 'Show places'} placement="left" arrow>
                                <IconButton
                                    size="large"
                                    sx={{
                                        background: showPlacesOnMap && '#575757',
                                        p: '0px', '&:hover': { backgroundColor: showPlacesOnMap ? '#575757' : 'white', },
                                    }}>
                                    {showPlacesOnMap ? <WrongLocationOutlinedIcon sx={{ fontSize: '1.25rem', color: 'white' }} /> : <AddLocationAltOutlinedIcon fontSize='medium' sx={{ fontSize: '1.25rem', color: 'gray' }} />}
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }

                    {!isMobile &&
                        <Box sx={{ position: 'absolute', bottom: '120px', right: '10px', zIndex: 1000, borderRadius: 1, }}>
                            <PhotographsFilter />
                        </Box>
                    }
                </MapContainer>
            </Box >

            <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                {isFetching ? <CircularProgress size={50} /> : null}
            </Box>

            {(isPhotographsDrawerOpened && clickedPhotographyId) && (!isMobile ? <PhotographyContainer id={clickedPhotographyId} /> : <MobilePhotographyContainer id={clickedPhotographyId} />)}
            {(isPlacesDrawerOpened && clickedPlaceId) && <PhotographyPlaceContainer id={clickedPlaceId} />}
        </Header >
    )
}
