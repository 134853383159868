import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import OptionDrawer from '../drawer/drawer.component';
import { useAuth } from '../../contexts/auth-context';
import { Avatar, Box, IconButton } from "@mui/material";
import { useSettings } from '../../contexts/settings-context';

import BackButton from '../ui/back-button.component';

export const MapHeader = ({ children }) => {
    const navigate = useNavigate();
    const { siteInfo } = useSettings();
    const { currentUser } = useAuth();

    const [open, setOpen] = useState(false);

    return (
        <>
            <header style={{ position: 'fixed', top: 10, left: 20, zIndex: 500 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgba(255,255,255,0.9)', border: '0.25px solid lightgrey', borderRadius: 10, padding: '5px' }}>
                    <BackButton sx={{
                        mr: '5px'
                    }} />
                    <img src={siteInfo?.logo} alt="logo" width='40' height='40' style={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
                </Box>
            </header>
            <header style={{ position: 'fixed', top: 10, right: 20, zIndex: 500 }}>
                {(currentUser && currentUser?.name) ?
                    <IconButton
                        aria-label="menu"
                        onClick={() => setOpen(true)}
                        sx={{ ml: 1, p: 0 }}
                        size='large'
                    >
                        <Avatar alt="Unknown" src={currentUser.avatar_image} className="header-avatar">
                            {currentUser.name.substring(0, 1)}
                        </Avatar>
                    </IconButton>
                    :
                    <IconButton sx={{ backgroundColor: 'rgba(255,255,255,0.9)', '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.6)" }, p: 1, ml: 1 }} size='large' edge="start" aria-label="open menu" onClick={() => setOpen(true)} >
                        <MenuIcon />
                    </IconButton>
                }
            </header>
            {open ? <OptionDrawer open={open} setOpen={setOpen} /> : null}

            {children}
        </>
    )
}