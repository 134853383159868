import React, { useState, useMemo } from "react";
import BackButton from '../ui/back-button.component';
import OptionDrawer from '../drawer/drawer.component';
import { TuneOutlined } from "@mui/icons-material/";
import { Menu, Public, Search } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { useSettings } from "../../contexts/settings-context";
import { BottomNavigation, BottomNavigationAction, Box, Typography, IconButton, Badge } from "@mui/material";
import { usePhotographsContext } from "../../contexts/photographs-context";

const MobileHeader = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { siteInfo, } = useSettings();
    const { totalPhotos, clickedTags, showMobileFilter, setShowMobileFilter } = usePhotographsContext();

    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);

    const navigationItems = useMemo(() => [
        { label: "Photos", icon: <Search />, path: '/' },
        { label: "Map", icon: <Public />, path: '/map' },
        { label: "Menu", icon: <Menu />, path: null }
    ], []);

    const handleNavigation = (newValue, path) => {
        setValue(newValue);
        if (path) {
            navigate(path);
            setOpen(false);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
            setOpen(!open);
        }
    };

    return (
        <>
            {location.pathname === '/map' ?
                <>
                    <header>
                        <BackButton sx={{ backgroundColor: '#F1F1F1', position: 'absolute', top: 10, left: 10, zIndex: 1000, }} />
                    </header>
                    {children}
                </> : <>
                    {location.pathname === '/' || location.pathname.includes('/place-details') ?
                        <>
                            <header>
                                <Box display={'flex'} alignItems={'center'} borderRadius={20} m={1} sx={{
                                    backgroundColor: '#F1F1F1',
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    zIndex: open ? 1 : 9999,
                                }}>
                                    <Box width='40px' height='40px' onClick={() => { navigate("/"); }}>
                                        <img src={siteInfo?.logo} alt="logo" width='40px' height='40px' />
                                    </Box>
                                    <Box display={'flex'} justifyContent={'center'} sx={{ width: '100%' }}>
                                        {totalPhotos !== 0 &&
                                            <Typography sx={{ color: 'black', textAlign: 'center' }} variant='body'>{totalPhotos} photographies</Typography>
                                        }
                                    </Box>
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                                        badgeContent={clickedTags.length} color="success">
                                        <IconButton
                                            size="large"
                                            aria-label='filter button badge'
                                            onClick={() => { setShowMobileFilter(!showMobileFilter); }}
                                            sx={{
                                                background: 'transparent',
                                                p: '5px',
                                                mr: '5px',
                                                color: 'black',
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                },

                                            }}
                                        >
                                            <TuneOutlined color="black" />
                                        </IconButton>
                                    </Badge>
                                </Box>
                            </header>

                            <footer>
                                <BottomNavigation
                                    showLabels
                                    value={value}
                                    sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1200, "& .Mui-selected": { color: "black !important", fontWeight: 500 } }}
                                    onChange={(event, newValue) => handleNavigation(newValue, navigationItems[newValue].path)} >
                                    {navigationItems.map((item, index) => (
                                        <BottomNavigationAction
                                            key={index}
                                            label={item.label}
                                            icon={item.icon}
                                        />
                                    ))}
                                </BottomNavigation>
                            </footer>

                        </> : null
                    }

                    {open ? <OptionDrawer open={open} setOpen={setOpen} /> : null}
                    {children}
                </>}
        </>
    );
}

export default MobileHeader;
