import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../contexts/auth-context';
import { Box, Grid, Typography } from '@mui/material';
import Header from '../../components/header.components';
import { useRandomImage } from '../../hooks/useRandomImage';
import { useSettings } from '../../contexts/settings-context';
import { getIfTokenIsExpired, resetPassword } from '../../services/auth.services';
import ResetPasswordForm from '../../components/auth/reset-password-form.component';

const ResetPassword = () => {
    const navigate = useNavigate();
    const { isMobile } = useSettings();
    const { data } = useRandomImage();
    const { token, email } = useParams();
    const { setSuccessMessage, setErrorMessage, currentUser, userLogoutHandler } = useAuth();

    useEffect(() => {
        async function checkToken() {
            try {
                const response = await getIfTokenIsExpired(token);

                if (response.status === 422) {
                    setErrorMessage(response.error);
                }

                if (currentUser?.email === email) {
                    await userLogoutHandler();
                }
            } catch (error) {
                setErrorMessage("Something went wrong")
            }
        }
        checkToken();
    }, [token]);

    const onSubmit = async (values) => {
        if (email) {
            values.email = decodeURIComponent(email);
        }
        values.token = token;

        try {
            const result = await resetPassword(values);
            if (result.status === 200) {
                setSuccessMessage(result.message);
                navigate('/login')
            }
        } catch (error) {
            setErrorMessage("Something went wrong");
        }
    }

    return (
        <Header>
            <Box style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: !isMobile && `url('${data?.image}')`, backgroundSize: 'cover' }}>
                <Grid container justifyContent="center" alignItems="center" >
                    <Grid item xs={11} sm={8} md={6} lg={4} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} sx={{ background: !isMobile && '#f0f0f0', borderRadius: 5, py: 3 }}>
                        <Typography variant="body2" gutterBottom fontSize={"24px"} mb={3}>Change password</Typography>
                        <ResetPasswordForm onSubmit={onSubmit} isMobile={isMobile} />
                    </Grid>

                    {!isMobile && <Box sx={{ position: 'absolute', bottom: 5, right: 5, background: '#0000008e', color: 'white', p: 3, borderRadius: 3 }}>
                        <Typography>{data?.title}</Typography>
                    </Box>}
                </Grid>
            </Box>
        </Header>
    )
}

export default ResetPassword