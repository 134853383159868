export async function getImageTypeFromUrl(imageUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const aspectRatio = this.width / this.height;
      if (aspectRatio > 1) {
        resolve("landscape");
      } else {
        resolve("portrait");
      }
    };
    img.onerror = function () {
      reject("Error loading image");
    };
    img.src = imageUrl;
  });
}

export async function getImageInfo(imageUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const aspectRatio = this.width / this.height;
      let type;
      if (aspectRatio > 1) {
        type = "landscape";
      } else {
        type = "portrait";
      }
      resolve({ type, dimensions: { width: this.width, height: this.height } });
    };
    img.onerror = function () {
      reject("Error loading image");
    };
    img.src = imageUrl;
  });
}
