import { useQuery } from "@tanstack/react-query";
import {
  getPlaceDetailsOnMap,
  getPlaceById,
  getMapPlaces,
} from "../services/places.services";

export const useGetPlace = (id) => {
  const { data, isFetched, isLoading, error, refetch } = useQuery({
    queryKey: [`place/${id}`],
    queryFn: () => getPlaceById(id),
    keepPreviousData: true,
    enabled: !!id,
  });

  return { data, isLoading, error, isFetched, refetch };
};

export const useGetMapPlaces = () => {
  const { data, isFetched, isLoading, error, isFetching, refetch } = useQuery({
    queryKey: ["map-places"],
    queryFn: () => getMapPlaces(),
    keepPreviousData: true,
  });

  return {
    data,
    isFetched,
    isLoading,
    error,
    refetch,
    isFetching,
  };
};

export const usePlaceMap = (id) => {
  const { data, isFetched, isLoading, error, refetch } = useQuery({
    queryKey: [`place-map/${id}`],
    queryFn: () => getPlaceDetailsOnMap(id),
    keepPreviousData: true,
  });

  return { data, isLoading, error, isFetched, refetch };
};
