import DesktopHeader from './headers/desktop-header.component';
import MobileHeader from './headers/mobile-header.component';

import { useSettings } from '../contexts/settings-context';

const Header = ({ children }) => {
    const { isMobile } = useSettings();

    return (
        <>
            {isMobile ? <MobileHeader children={children} /> : <DesktopHeader children={children} />}
        </>
    );
}

export default Header;