import * as yup from 'yup';
import { useState } from 'react';
import { PhotoCamera } from "@mui/icons-material";
import { useAuth } from '../../../contexts/auth-context';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { updateUser } from '../../../services/user.services';
import { Avatar, TextField, Button, IconButton, Box, Grid } from '@mui/material';

const validationSchema = yup.object({
    name: yup.string().min(6, 'Your name must be at least 6 characters!').max(255, 'The name must be maximum 255 characters!').required('Name is required'),
});

const MobileSettingsForm = () => {
    const [avatarImage, setAvatarImage] = useState();
    const { currentUser, currentToken, setIsLoaded, setErrorMessage, changeCurrentUser, setSuccessMessage } = useAuth();

    const handleFileChange = (event) => {
        setAvatarImage(event.target.files[0]);
    };

    async function onSubmitHandler(values) {
        setIsLoaded(true);
        setErrorMessage('');
        setSuccessMessage('');

        if (avatarImage) {
            values.avatarImage = avatarImage;
        }

        try {
            const result = await updateUser(values, currentToken);

            if (result?.status === 200) {
                setSuccessMessage("You update your info!")
                await changeCurrentUser(currentToken);
            }
        } catch (error) {
            setErrorMessage(error);
        } finally {
            setIsLoaded(false);
        }
    }

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={10}>
                <Formik
                    enableReinitialize
                    onSubmit={onSubmitHandler}
                    initialValues={{ name: currentUser?.name }}
                    validationSchema={validationSchema}
                >
                    <Form>
                        <Grid item xs={12} md={12} lg={12}>
                            <Box display={'flex'} justifyContent={'center'}>
                                <label htmlFor="avatar" style={{ position: 'relative' }}>
                                    <Avatar
                                        variant='circular'
                                        alt={currentUser?.name}
                                        src={avatarImage ? URL.createObjectURL(avatarImage) : currentUser?.avatar_image}
                                        sx={{ width: 128, height: 128, mb: 2, cursor: 'pointer', border: '1px solid #000005' }}
                                    />
                                    <IconButton
                                        size='large'
                                        htmlFor="avatar"
                                        component="label"
                                        sx={{ position: 'absolute', top: -5, right: -5 }}
                                    >
                                        <PhotoCamera fontSize='medium' sx={{ stroke: '#FFFFFF', strokeWidth: 1, fill: '#000005', }} />
                                    </IconButton>
                                </label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    id="avatar"
                                    name="avatar"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                            </Box>
                        </Grid>

                        <Field
                            fullWidth
                            id="name"
                            name="name"
                            as={TextField}
                            type="text"
                            label="Name"
                            variant="outlined"
                        />
                        <ErrorMessage name="name" component={'div'} style={{ color: 'red' }} />

                        <Button type="submit" variant="contained" color="success" fullWidth sx={{ borderRadius: 15, mt: 2, height: 50 }}>
                            Update
                        </Button>
                    </Form>
                </Formik>
            </Grid>
        </Grid>
    );
};

export default MobileSettingsForm;