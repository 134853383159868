import { request } from "../helpers/request";

//* Return a photos for home page
export function getAllPhotos(
  pageParam,
  perPage,
  currentToken,
  clickedTagsParams,
  latitude,
  longitude,
  filter
) {
  if (clickedTagsParams) {
    clickedTagsParams = clickedTagsParams?.map((tag) => tag.name).toString();
  }
  let config = {
    params: {
      page: pageParam,
      per_page: perPage,
      tags: clickedTagsParams,
      filter,
      latitude,
      longitude,
    },
  };

  if (currentToken) {
    config.headers = {
      Authorization: `Bearer ${currentToken}`,
    };
  }

  let products = request.get("/photographies", config).then((res) => res.data);
  return products;
}

//* Return a photos for map page
export async function getMapPhotos(clickedTagsParams) {
  if (clickedTagsParams) {
    clickedTagsParams = clickedTagsParams?.map((tag) => tag.name).toString();
  }

  try {
    const response = await request.get("/map/", {
      params: { tags: clickedTagsParams },
    });

    return response.data;
  } catch (error) {
    return error;
  }
}

//* Return details for details photo page
export async function getPhotoById(id, currentToken) {
  let config = {};
  if (currentToken) {
    config.headers = {
      Authorization: `Bearer ${currentToken}`,
    };
  }

  try {
    const response = await request.get(`photographies/${id}`, config);

    return response.data;
  } catch (error) {
    throw error;
  }
}

//* Return information for photo when it clicked on map
export async function getPhotoOnMapById(id) {
  try {
    const response = await request.get(`/map/${id}`);

    return response.data;
  } catch (error) {
    throw error;
  }
}
