import { useQuery } from "@tanstack/react-query";
import { getRandomImage, getDailyPhoto } from "../services/settings.services";

export const useRandomImage = () => {
  return useQuery({
    queryKey: ["random-image"],
    queryFn: () => getRandomImage(),
    cacheTime: 0,
    staleTime: 0,
    enabled: true,
  });
};

export const useDailyPhoto = (id) => {
  return useQuery({
    queryKey: [`daily-photo`],
    queryFn: () => getDailyPhoto(),
    keepPreviousData: true,
  });
};
