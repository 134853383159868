import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { useAuth } from '../../contexts/auth-context';
import OptionDrawer from '../drawer/drawer.component';
import { MapHeader } from './map-header.component';
import { useSettings } from '../../contexts/settings-context';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, AppBar, Toolbar, Avatar, Grid, Divider, Link, Typography } from "@mui/material";

const DesktopHeader = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const { currentUser } = useAuth();
    const { siteInfo } = useSettings();

    const [open, setOpen] = useState(false);

    const isMapPage = ['/map', '/login', '/register', '/info', '/forgotten-password'].includes(location.pathname) || location.pathname.includes('/reset-password');

    return (
        <>
            {isMapPage ? <MapHeader children={children} /> :
                <>
                    <AppBar
                        id={'myHeader'}
                        elevation={0}
                        sx={{ backgroundColor: 'white' }}>
                        <Toolbar sx={{ px: '0 !important', py: 0.4, }}>
                            <Grid container sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', }}>
                                <Grid item sm={1} xs={false} />
                                <Grid item sm={10} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={siteInfo?.logo} alt="logo" width='40' height='40' style={{ cursor: 'pointer' }} onClick={() => location.pathname !== '/' && navigate('/')} />
                                        <Typography variant='h6' sx={{ textTransform: 'lowercase', pl: 1, color: 'black' }}>{location.pathname === ('/') ? `${siteInfo?.name} / photos` : siteInfo?.name}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Link sx={{
                                            cursor: 'pointer', textUnderlineOffset: 5, mx: 1,
                                            color: 'black', mr: 1, fontWeight: location.pathname === ('/') && 600
                                        }} onClick={() => navigate('/')} underline={location.pathname === ('/') ? 'always' : 'none'}>
                                            Photos
                                        </Link>

                                        <Link sx={{
                                            cursor: 'pointer', mx: 1,
                                            textUnderlineOffset: 5, color: 'black', fontWeight: location.pathname === ('/map') && 600
                                        }} underline={location.pathname === ('/map') ? 'always' : 'none'} onClick={() => navigate('/map')}>Map</Link>
                                        {(currentUser && currentUser?.name) ?
                                            <IconButton
                                                size='small'
                                                aria-label="menu"
                                                sx={{ ml: 1 }}
                                                onClick={() => setOpen(true)}
                                            >
                                                <Avatar alt="Unknown" src={currentUser?.avatar_image} className="header-avatar">
                                                    {currentUser?.name.substring(0, 1)}
                                                </Avatar>
                                            </IconButton>
                                            :
                                            <>
                                                {/* <Link sx={{
                                                    cursor: 'pointer', textUnderlineOffset: 5,
                                                    color: 'black', mx: 1, fontWeight: location.pathname === ('/register') && 600
                                                }} underline={location.pathname === ('/register') ? 'always' : 'none'} onClick={() => navigate('/register')}>Sign up</Link> */}
                                                <Link sx={{
                                                    cursor: 'pointer', textUnderlineOffset: 5,
                                                    color: 'black', mx: 1, fontWeight: location.pathname === ('/login') && 600
                                                }} underline={location.pathname === ('/login') ? 'always' : 'none'} onClick={() => navigate('/login')}>Sign in</Link>
                                                <IconButton sx={{ color: 'black', p: 1, ml: 1 }} size="small" edge="start" aria-label="open drawer" onClick={() => setOpen(true)}>
                                                    <MenuIcon />
                                                </IconButton>
                                            </>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item sm={1} xs={false} />
                            </Grid>
                        </Toolbar>
                        {location.pathname !== ('/') && <Divider sx={{ width: '100%', margin: 'auto' }} />}
                    </AppBar>

                    {open ? <OptionDrawer open={open} setOpen={setOpen} /> : null}
                    {children}
                </>}
        </>
    );
}

export default DesktopHeader;