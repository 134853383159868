import * as yup from 'yup';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PhotoCamera } from "@mui/icons-material";
import { useAuth } from '../../../contexts/auth-context';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { updateUser } from '../../../services/user.services';
import { Paper, Typography, Avatar, TextField, Button, IconButton, Box, Grid } from '@mui/material';

const validationSchema = yup.object({
    name: yup.string().min(6, 'Your name must be at least 6 characters!').max(255, 'The name must be maximum 255 characters!').required('Name is required'),
});

const SettingsForm = () => {
    const [avatarImage, setAvatarImage] = useState();
    const { currentUser, currentToken, setIsLoaded, setErrorMessage, changeCurrentUser, setSuccessMessage } = useAuth();

    const handleFileChange = (event) => {
        setAvatarImage(event.target.files[0]);
    };

    async function onSubmitHandler(values) {
        setIsLoaded(true);
        setErrorMessage('');
        setSuccessMessage('');

        if (avatarImage) {
            values.avatarImage = avatarImage;
        }

        try {
            const result = await updateUser(values, currentToken);

            if (result?.status === 200) {
                setSuccessMessage("You update your info!")
                await changeCurrentUser(currentToken);
            }
        } catch (error) {
            setErrorMessage(error);
        } finally {
            setIsLoaded(false);
        }
    }

    return (
        <Paper sx={{ borderRadius: 10, padding: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='body1' align='left'>
                    Edit profile
                </Typography>

                <Typography variant='body1' component={Link} to='/me' sx={{ textDecoration: 'none', color: 'gray' }}>
                    Cancel
                </Typography>
            </Box>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={6} sm={6} md={6}>
                    <Formik
                        initialValues={{ name: '' }}
                        validationSchema={validationSchema}
                        onSubmit={onSubmitHandler}
                        enableReinitialize
                    >
                        <Form>
                            <Grid item xs={12} md={12} lg={12}>
                                <Box display={'flex'} justifyContent={'center'}>
                                    <label htmlFor="avatar" style={{ position: 'relative' }}>
                                        <Avatar
                                            variant='circular'
                                            alt={currentUser?.name}
                                            src={avatarImage ? URL.createObjectURL(avatarImage) : currentUser?.avatar_image}
                                            sx={{ width: 128, height: 128, mb: 2, cursor: 'pointer', border: '1px solid #000005' }}
                                        />
                                        <IconButton
                                            size='large'
                                            htmlFor="avatar"
                                            component="label"
                                            sx={{ position: 'absolute', top: -5, right: -5 }}
                                        >
                                            <PhotoCamera fontSize='medium' sx={{ stroke: '#FFFFFF', strokeWidth: 1, fill: '#000005', }} />
                                        </IconButton>
                                    </label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        id="avatar"
                                        name="avatar"
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                </Box>
                            </Grid>

                            <Field
                                as={TextField}
                                type="text"
                                label="Name"
                                variant="outlined"
                                fullWidth
                                id="name"
                                name="name"
                            />
                            <ErrorMessage name="name" component={'div'} style={{ color: 'red' }} />

                            <Button type="submit" variant="contained" color="success" fullWidth sx={{ borderRadius: 15, mt: 2 }}>
                                Save changes
                            </Button>
                        </Form>
                    </Formik>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default SettingsForm;