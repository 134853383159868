import { Box, Grid, Typography } from '@mui/material';
import { useAuth } from '../../contexts/auth-context';
import Header from '../../components/header.components';
import { useRandomImage } from '../../hooks/useRandomImage';
import { useSettings } from '../../contexts/settings-context';
import { forgottenPassword } from '../../services/auth.services';
import ForgottenPasswordForm from '../../components/auth/forgotten-password-form.component';

const ForgottenPassword = () => {
    const { data } = useRandomImage();
    const { isMobile } = useSettings();
    const { setErrorMessage, setSuccessMessage } = useAuth();

    const onSubmit = async (values) => {
        try {
            const result = await forgottenPassword({ email: values.email });

            if (result?.status === 200) {
                setSuccessMessage(result?.message)
            }
        } catch (error) {
            setErrorMessage("Something went wrong")
        }
    };

    return (
        <Header>
            <Box style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: !isMobile && `url('${data?.image}')`, backgroundSize: 'cover' }}>
                <Grid container justifyContent="center" alignItems="center" >
                    <Grid item xs={11} sm={8} md={6} lg={4} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} sx={{ background: !isMobile && '#f0f0f0', borderRadius: 5, py: 3 }}>
                        <Typography variant="body2" gutterBottom fontSize={"24px"} mb={3}>Forgotten password</Typography>
                        <ForgottenPasswordForm onSubmit={onSubmit} isMobile={isMobile} />
                    </Grid>

                    {!isMobile && <Box sx={{ position: 'absolute', bottom: 5, right: 5, background: '#0000008e', color: 'white', p: 3, borderRadius: 3 }}>
                        <Typography>{data?.title}</Typography>
                    </Box>}
                </Grid>
            </Box>
        </Header>
    )
}

export default ForgottenPassword