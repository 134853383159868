import { Link } from 'react-router-dom';
import { useSettings } from '../contexts/settings-context';
import { Grid, Typography, Box, Divider } from '@mui/material';

const FooterComponent = () => {
    const currentYear = new Date().getFullYear();
    const { isMobile, siteInfo } = useSettings();

    return (
        <>
            <Divider />
            <Grid container display={'flex'} justifyContent={'space-between'} flexDirection={'row'} alignItems={'center'} p={isMobile ? 0.5 : 1}>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }} >
                    <img src={siteInfo?.logo} width={30} height={30} alt="logo" />
                    <Typography variant='body2' fontSize={18} sx={{ pl: 1, color: 'black', textTransform: 'uppercase' }}>{siteInfo?.name}</Typography>
                </Grid>
                <Grid item display={'flex'} flexDirection={isMobile ? 'column' : 'row'} justifyContent={isMobile ? 'center' : 'space-between'}>
                    <Box sx={{ display: isMobile && 'flex', justifyContent: isMobile && "flex-end" }}>
                        <Typography sx={{ pr: 1, textAlign: isMobile ? 'center' : 'left', }} variant='caption'>&#169; {currentYear} All rights reserved.</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{ pr: 1 }} variant='caption' component={Link} to='/terms-of-use'>Terms of Use</Typography>
                        <Typography sx={{ pr: 1 }} variant='caption' component={Link} to='/privacy-policy'>Privacy and Policy</Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default FooterComponent