import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth-context';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

const DrawerItem = ({ to, icon, primaryText, onClick }) => {
    const { userLogoutHandler } = useAuth();

    const navigate = useNavigate();

    const onClickHandler = async () => {
        onClick();
        if (to === '/exit') {
            await userLogoutHandler();
        } else {
            navigate(to);
        }
    }

    return (
        <ListItemButton onClick={() => onClickHandler()} style={{ cursor: 'pointer', marginTop: 5 }}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{primaryText}</ListItemText>
        </ListItemButton>
    );
};

export default DrawerItem;