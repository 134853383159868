import { useNavigate, Link } from 'react-router-dom';
import { Grid, Typography, Box } from '@mui/material';
import { useAuth } from '../../contexts/auth-context';
import Header from '../../components/header.components';
import { loginUser } from '../../services/auth.services';
import { useRandomImage } from '../../hooks/useRandomImage';
import { useSettings } from '../../contexts/settings-context';
import LoginForm from '../../components/auth/login-form.component';

const LoginPage = () => {
    const navigate = useNavigate();
    const { siteInfo } = useSettings();
    const { data } = useRandomImage();
    const { setErrorMessage, changeCurrentUser } = useAuth();

    async function handleLogin(values) {
        setErrorMessage('');
        const result = await loginUser(values);

        if (result?.status === 200) {
            await changeCurrentUser(result?.data?.token);
            navigate('/', { replace: true });
        } else {
            setErrorMessage(result);
        }
    }

    return (
        <Header>
            <Box style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: `url('${data?.image}')`, backgroundSize: 'cover' }}>
                <Grid container justifyContent="center" mt={0} mb={0} spacing={0} alignItems="center" >
                    <Grid item xs={12} sm={6} md={8} lg={6} sx={{ background: '#f0f0f0', borderRadius: 5, }} p={5}>
                        <Grid item xs={10} sm={12} sx={{
                            display: 'flex', justifyContent: {
                                sm: 'center',
                                md: 'space-between',
                            }, alignItems: 'center', flexWrap: 'wrap'
                        }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: { xs: 2, md: 0 } }}>
                                <img src={siteInfo?.logo} alt="logo" width='40' height='40' style={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
                                <Typography variant='h5' sx={{ textTransform: 'uppercase', }}>&nbsp; Sign in</Typography>
                            </Box>

                            <Typography variant="body2">
                                Don't have an account? &nbsp;
                                <Link to={"/register"} style={{ color: 'green', cursor: 'pointer', textDecoration: 'underline', }}>Sign up!</Link>
                            </Typography>
                        </Grid>

                        <Grid container mt={3} display={'flex'} justifyContent={'space-between'}>
                            <Grid item xs={12} sm={12} md={4} sx={{ mt: { xs: 0, md: 3 } }}>
                                <Typography variant="body2" gutterBottom fontSize={"1.5rem"} sx={{ display: { xs: 'none', md: 'block' } }}>Like and Share</Typography>
                                <Typography variant='subtitle2' sx={{ color: '#868686', textAlign: { xs: 'center', md: 'left' }, fontSize: 14, mb: { xs: 2, md: 0 } }}>Mark the places you like and share photos from the places you visited.</Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6.5}>
                                <LoginForm onSubmit={handleLogin} navigate={navigate} />
                            </Grid>

                            <Box sx={{ position: 'absolute', bottom: 5, right: 5, background: '#0000008e', color: 'white', p: 3, borderRadius: 3 }}>
                                <Typography>{data?.title}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Header>
    );
};

export default LoginPage;