import { request } from "../helpers/request";

export async function registerUser(values) {
  try {
    await request.post(`/auth/register`, {
      name: values.name,
      email: values.email,
      password: values.password,
      password_confirmation: values.password_confirmation,
    });

    const result = await loginUser(values);

    return result;
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.errors.email
    ) {
      return error.response.data.errors.email;
    }
    return "Something went wrong.";
  }
}

export async function loginUser(values) {
  try {
    const res = await request.post(`/auth/login`, {
      email: values.email,
      password: values.password,
    });
    localStorage.setItem("token", res.data.token);

    return res;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return error.response.data.message;
    }
    return "Something went wrong.";
  }
}

export async function getUserData(token) {
  const result = await request
    .get("/user/info", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
  return result;
}

export async function userLogout(token) {
  const result = await request
    .post(`/auth/logout`, "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res)
    .catch((err) => err.message);
  return result;
}

export async function forgottenPassword(values) {
  try {
    const response = await request.post(`/forgotten-password`, {
      email: values.email,
    });
    response.data.status = response.status;
    return response.data;
  } catch (error) {
    throw new Error("Something went wrong");
  }
}

export async function getIfTokenIsExpired(token) {
  try {
    const response = await request.get(`/check-token/?token=${token}`);

    response.data.status = response.status;
    return response.data;
  } catch (error) {
    let errorMessage = "Something went wrong";
    let statusCode = error.response ? error.response.status : 401;

    if (error.response && error.response.data && error.response) {
      errorMessage = error.response.data;
    }

    return { error: errorMessage, status: statusCode };
  }
}

export async function resetPassword(values) {
  try {
    const response = await request.post(`/reset-password`, {
      email: values.email,
      token: values.token,
      password: values.password,
      password_confirmation: values.password_confirmation,
    });

    response.data.status = response.status;
    return response.data;
  } catch (error) {
    throw new Error("Something went wrong");
  }
}
